import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const BackEndEditUser: React.FC = () => {
  const [searchParams] = useSearchParams();
  const refferalCode = searchParams.get("refferalCode");
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    refferalCode: "",
    "time-register": "",
    userId: "",
    status: false,
    user: {
      phoneNumber: "",
      address: "",
      province: "",
      image_url: "",
      district: "",
      postalCode: "",
      distributorType: "",
      name: "",
      lastname: "",
    },
    bank: {
      bankName: "",
      bankAccount: "",
    },
    verify: false,
  });

  const [imagePreview, setImagePreview] = useState<string | null>(null); // พรีวิวภาพใหม่
  const [newImage, setNewImage] = useState<File | null>(null); // ไฟล์ภาพใหม่

  // ดึงข้อมูลผู้ใช้ตาม `refferalCode`
  useEffect(() => {
    if (!refferalCode) {
      Swal.fire("Error", "ไม่พบข้อมูลรหัสผู้ใช้งาน (refferalCode)", "error");
      return;
    }

    axios
      .get(
        `https://artelu-api.vercel.app/api/admin/backEndManager/getUser/${refferalCode}`
      )
      .then((response) => {
        setFormData(response.data);
        setImagePreview(response.data.user.image_url); // ตั้งค่าพรีวิวภาพ
      })
      .catch(() => {
        Swal.fire("Error", "ไม่สามารถดึงข้อมูลผู้ใช้ได้", "error");
      });
  }, [refferalCode]);

  // จัดการการแก้ไขข้อมูลในฟอร์ม
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldGroup?: string
  ) => {
    const { name, value } = e.target;

    if (fieldGroup) {
      setFormData((prev: any) => ({
        ...prev,
        [fieldGroup]: {
          ...prev[fieldGroup],
          [name]: value,
        },
      }));
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // จัดการการอัปโหลดภาพใหม่
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setNewImage(file); // เก็บไฟล์ภาพใหม่
      setImagePreview(URL.createObjectURL(file)); // พรีวิวภาพใหม่
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!refferalCode) {
      Swal.fire("Error", "ไม่พบค่า refferalCode", "error");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("refferalCode", refferalCode);
    formDataToSend.append("user", JSON.stringify(formData.user));
    formDataToSend.append("bank", JSON.stringify(formData.bank));
    formDataToSend.append("verify", String(formData.verify));
    formDataToSend.append("status", String(formData.status));

    if (newImage) {
      formDataToSend.append("image", newImage); // เพิ่มไฟล์ภาพใหม่ลงใน FormData
    }

    // console.log(JSON.stringify(formData));

    // แสดงสถานะการอัปเดตด้วย Swal
    Swal.fire({
      title: "กำลังอัปเดตข้อมูล...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // ใช้ Fetch API แทน axios เพื่อรองรับ FormData โดยตรง
    fetch("https://artelu-api.vercel.app/api/admin/backEndManager/updateUser", {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // ปิด Swal loading และแสดงข้อความสำเร็จ
        Swal.close();
        Swal.fire("สำเร็จ", "บันทึกข้อมูลเรียบร้อย", "success");
        navigate("/backEndManager/itemManager"); // กลับไปหน้า itemManager
      })
      .catch((error) => {
        // ปิด Swal loading และแสดงข้อความข้อผิดพลาด
        Swal.close();
        Swal.fire(
          "Error",
          `ไม่สามารถบันทึกข้อมูลได้: ${error.message}`,
          "error"
        );
      });
  };

  const handleApprove = () => {
    if (!refferalCode || !formData.userId) {
      Swal.fire(
        "Error",
        "ข้อมูลไม่ครบถ้วน (refferalCode หรือ userId)",
        "error"
      );
      return;
    }

    Swal.fire({
      title: "ยืนยันการอนุมัติ",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        Swal.showLoading(); // แสดง Loading ขณะเรียก API

        return fetch(
          "https://artelu-api.vercel.app/api/admin/backEndManager/approve",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              refferalCode: refferalCode, // ใช้ refferalCode ที่ได้จาก URL
              userId: formData.userId, // ดึง userId จากข้อมูล formData
            }),
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(() => {
            Swal.fire("Success", "อนุมัติสำเร็จ!", "success").then(() => {
              navigate("/backEndManager/itemManager"); // กลับไปหน้า itemManager
            });
          })
          .catch((error) => {
            Swal.fire("Error", `เกิดข้อผิดพลาด: ${error.message}`, "error");
          });
      },
    });
  };

  if (!formData) {
    return <p>Loading user data...</p>;
  }
  return (
    <div className="container-sm mt-3 p-5">
      <h2 className="text-center mb-4">
        Tag: {refferalCode} บัญชี: {formData.user.name} {formData.user.lastname}
      </h2>
      <form onSubmit={handleSubmit}>
        {/* User Information */}
        <div className="cards mb-4 ">
          <div className="p-3 fs-4 card-header bg-secondary text-white">
            ข้อมูลผู้ใช้
          </div>
          <div className="p-4 card-bodys">
            <div className="row">
              <div className="col-md-6">
                <label>ชื่อ</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formData.user.name}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-6">
                <label>นามสกุล</label>
                <input
                  type="text"
                  name="lastname"
                  className="form-control"
                  value={formData.user.lastname}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>เบอร์โทรศัพท์</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  value={formData.user.phoneNumber}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-6 mt-3">
                <label>ที่อยู่</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={formData.user.address}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label>เขต</label>
                <input
                  type="text"
                  name="district"
                  className="form-control"
                  value={formData.user.district}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label>จังหวัด</label>
                <input
                  type="text"
                  name="province"
                  className="form-control"
                  value={formData.user.province}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
              <div className="col-md-4 mt-3">
                <label>รหัสไปรษณีย์</label>
                <input
                  type="text"
                  name="postalCode"
                  className="form-control"
                  value={formData.user.postalCode}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* Bank Information */}
        <div className="cards  mb-4">
          <div className="p-3 fs-4 card-header bg-secondary text-white">
            ข้อมูลธนาคาร
          </div>
          <div className="p-4  card-body">
            <div className="row">
              <div className="col-md-6">
                <label>ชื่อธนาคาร</label>
                <input
                  type="text"
                  name="bankName"
                  className="form-control"
                  value={formData.bank.bankName}
                  onChange={(e) => handleInputChange(e, "bank")}
                />
              </div>
              <div className="col-md-6">
                <label>เลขบัญชี</label>
                <input
                  type="text"
                  name="bankAccount"
                  className="form-control"
                  value={formData.bank.bankAccount}
                  onChange={(e) => handleInputChange(e, "bank")}
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div>
          {/* Status */}

          <div className="p-3 fs-4 card-header bg-secondary text-white">
            การอนุญาต
          </div>
          <div className="p-4 col-md-6">
            <label>สถานะผู้ใช้ (Active/Inactive)</label>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="statusCheckbox"
                checked={formData.status} // ใช้ checked เพื่อแสดงสถานะ
                onChange={(e) =>
                  setFormData((prev: any) => ({
                    ...prev,
                    status: e.target.checked, // ใช้ e.target.checked เพื่อเปลี่ยนค่าเป็น true/false
                  }))
                }
              />
              <label className="form-check-label" htmlFor="statusCheckbox">
                {formData.status ? "Active" : "Inactive"}
              </label>
            </div>
          </div>

          {/* ปุ่ม Verified */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <button
                  type="button" // เพิ่ม type="button" เพื่อไม่ให้กระตุ้น onSubmit ของ form
                  className={`btn btn-lg w-100 ${
                    formData.verify ? "btn-secondary" : "btn-success"
                  }`}
                  onClick={handleApprove}
                  disabled={formData.verify} // กดไม่ได้ถ้า verify เป็น true
                >
                  {formData.verify ? "Already Verified" : "Verify"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* พรีวิวภาพและอัปโหลดภาพใหม่ */}
        <div className="col-md-6 mt-3">
          <label>ภาพปัจจุบัน</label>
          {imagePreview && (
            <div>
              <img
                src={imagePreview}
                alt="Preview"
                className="img-thumbnail mb-2"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            className="form-control"
            onChange={handleImageChange}
          />
        </div>
        <hr />

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/backEndManager/itemManager")}
          >
            กลับไป
          </button>
          <button type="submit" className="btn btn-primary">
            บันทึกการเปลี่ยนแปลง
          </button>
        </div>
      </form>
      <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>
                <p>
                  <strong>ข้อมูลแสดง:</strong>
                  <br />
                  แสดงข้อมูลสมาชิกทั้งหมด
                </p>
                <p>
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ตรวจสอบอนุมัติสมาชิก ตัวแทนจำหน่าย ศิลปิน หมอดู..<br />
                  2.ปรับแกไขข้อมูลสมาชิก<br />
                  3.สร้างสินค้า<br />
                  4.แสดงสินค้าที่ขายได้<br />
                  5.สร้างรอบโอนเงินให้สมาชิก<br />
                </p>
              </div>
    </div>
  );
};

export default BackEndEditUser;
