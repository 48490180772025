import React, { useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FormPage from "./FormPage";
import ResultPage from "./ResultPage";
import ImagePage from "./ImagePage";
import PaymentPage from "./PaymentPage";
import HomePage from "./HomePage";
import DistributorSignup from "./DistributorSignup"; // นำเข้า DistributorSignup
import BackEndManager from "./BackEndManager";
import ConfirmPage from "./ConfirmPage";
import CancelPage from "./CancelPage";
import TarosPage from "./TarosPage";
import TarotHome from "./TarotHome";
import TarotPayment from "./TarotPayment";
import CheckListDescriptionPage from "./CheckListDescriptionPage";
import RedirecUrlComponent from "./RedirecUrlComponent";
import FortunePage from "./FortunePage";
import DistributorSignupMember from "./DistributorSignupMember";
import MemberManager from "./MamberManager";
import BackEndItemManager from "./BackEndItemManager";
import RegistorComplete from "./RegistorComplete";
import SuccessPayment from "./SuccessPayment";
import BackEndEditUser from "./BackEndEditUser";
import BackEndPayUsers from "./BackEndPayUsers";
import ProtectedRoute from "./ProtectedRoute";

const App: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    day: "",
    month: "",
    year: "",
    zodiac: "",
    userId: "",
    image: null as File | null, // ปรับให้เก็บไฟล์หรือค่าว่าง (null)
    refferalCode: "",
    productType: "",
    itemId: "",
  });
  const [generatedImage, setGeneratedImage] = useState("");
  const [watermarkedImage, setWatermarkedImage] = useState("");
  const [zodiacNameEn, setZodiacNameEn] = useState("");

  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [imageLoading, setImageLoading] = useState({
    titleText: true,
    mainImage: true,
    buyButton: true,
  }); // สถานะการโหลดแต่ละภาพ

  const nextStep = (
    image?: string,
    zodiacName?: string,
    watermarkedImage?: string
  ) => {
    if (image) {
      setGeneratedImage(image);
    }
    if (zodiacName) {
      setZodiacNameEn(zodiacName);
    }
    if (watermarkedImage) {
      setWatermarkedImage(watermarkedImage);
    }
    setStep(step + 1);
  };

  const updateFormData = (data: any) => {
    setFormData({ ...formData, ...data });
  };

  return (
    <Router>
      <Routes>
        {/*redirec url}*/}
        <Route path="/redirec-url" element={<RedirecUrlComponent />} />
        {/* เส้นทางสำหรับหน้า DistributorSignup */}
        <Route path="/distributorSignup" element={<DistributorSignup />} />
        <Route
          path="/distributor-signUp-member"
          element={<DistributorSignupMember />}
        />

        {/* Login Route */}
        <Route path="/backEndManager/login" element={<BackEndManager />} />

        {/* Protected Routes */}
        <Route
          path="/backEndManager/itemManager"
          element={
            <ProtectedRoute>
              <BackEndItemManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/backEndManager/itemManager/editUser"
          element={
            <ProtectedRoute>
              <BackEndEditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/backEndManager/itemManager/payUsers"
          element={
            <ProtectedRoute>
              <BackEndPayUsers />
            </ProtectedRoute>
          }
        />

        {/* member*/}
        <Route path="/service/member" element={<MemberManager />} />

        {/* เส้นทางใหม่สำหรับหน้า ConfirmPage */}
        <Route path="/confirm" element={<ConfirmPage />} />
        <Route path="/cancel" element={<CancelPage />} />
        <Route path="/successPayment" element={<SuccessPayment />} />

        {/*สมัครสำเร็จ */}
        <Route path="/registorComplete" element={<RegistorComplete />} />

        {/* taros */}
        <Route
          path="/tarot/home"
          element={
            <TarotHome updateFormData={updateFormData} formData={formData} />
          }
        />
        <Route
          path="/tarot/aday"
          element={
            <TarosPage updateFormData={updateFormData} formData={formData} />
          }
        />
        <Route
          path="/tarot/payment"
          element={<TarotPayment formData={formData} />}
        />

        {/*Fortune*/}
        <Route path="/fortune/home" element={<FortunePage />} />

        <Route path="/tarot/checkList" element={<CheckListDescriptionPage />} />

        {/* เส้นทางเดิมสำหรับขั้นตอนอื่น ๆ */}
        <Route
          path="/"
          element={
            <>
              {step === 1 && (
                <HomePage
                  nextStep={() => setStep(2)}
                  formData={formData} // ส่ง formData ไปยัง HomePage
                  updateFormData={updateFormData} // อัปเดต userId ใน formData
                />
              )}
              {step === 2 && (
                <FormPage
                  nextStep={() => setStep(3)}
                  prevStep={() => setStep(1)}
                  updateFormData={updateFormData}
                  formData={formData}
                />
              )}
              {step === 3 && (
                <ResultPage
                  formData={formData}
                  nextStep={(image, zodiacName, watermarkedImage) => {
                    nextStep(image, zodiacName, watermarkedImage);
                    setStep(4);
                  }}
                  prevStep={() => setStep(2)} // เพิ่ม prevStep เพื่อให้ย้อนกลับไปยัง Step 2 ได้
                  setGeneratedImage={setGeneratedImage}
                  setWatermarkedImage={setWatermarkedImage}
                  updateFormData={updateFormData}
                />
              )}
              {step === 4 && (
                <PaymentPage
                  formData={formData}
                  nextStep={(updatedFormData) => {
                    setFormData(updatedFormData);
                    setStep(5);
                  }}
                  prevStep={() => setStep(3)} // เพิ่ม prevStep เพื่อให้ย้อนกลับไปยัง Step 3 ได้
                />
              )}
              {step === 5 && (
                <ImagePage
                  formData={formData}
                  generatedImage={generatedImage}
                  watermarkedImage={watermarkedImage}
                  zodiacNameEn={zodiacNameEn}
                  prevStep={() => setStep(4)} // เพิ่ม prevStep เพื่อให้ย้อนกลับไปยัง Step 4 ได้
                />
              )}
            </>
          }
        />
      </Routes>

      <footer className="footer">Copy right 2024 Artteluh Thailand</footer>
    </Router>
  );
};

export default App;
