import React, { useState,useEffect} from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Taros.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const TarotPayment: React.FC<{
  formData: any;
}> = ({ formData}) => {
    const navigate = useNavigate(); // Initialize navigate function
  const [paymentMethod, setPaymentMethod] = useState("linepay"); // ตั้งค่าเป็น linepay โดยตรง
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {

    console.log(formData.userId);
    console.log(formData.image);

  }, []);


  const handleReferralCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferralCode(e.target.value);
  };

  const handleBackhome = () => {
    navigate("/tarot/home"); // Navigate to the desired route
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Submitting payment...");
  
    if (paymentMethod === "linepay") {
      try {
        // แสดง Swal ขณะที่คำขอกำลังดำเนินการ
        Swal.fire({
          title: "กำลังทำการชำระเงิน...",
          text: "โปรดรอสักครู่",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        const formDataToSend = new FormData();
        if (formData.userId) {
          formDataToSend.append("userId", formData.userId);
          console.log("userId:", formData.userId);
        }
        if (formData.image) {
          formDataToSend.append("image", formData.image);
          console.log("image:", formData.image);
        }
        if (referralCode && referralCode !== "") {
          formDataToSend.append("refferalCode", referralCode);
          console.log("referralCode:", referralCode);
        }
        formDataToSend.append("productType", "TAROT_V1");
        formDataToSend.append("itemId", formData.itemId);

  
        const response = await fetch("https://artelu-api.vercel.app/api/paymentedUserBye", {
          method: "POST",
          body: formDataToSend,
        });
  
        const data = await response.json();
        console.log("Response from server:", data);
  
        if (response.ok) {
          Swal.close(); // ปิด Swal เมื่อสำเร็จ
          window.location.href = data.paymentUrl; // นำผู้ใช้ไปยัง URL ของ Line Pay
        } else {
          Swal.fire({
            icon: "error",
            title: "ชำระเงินไม่สำเร็จ",
            text: data.message || "มีบางอย่างผิดพลาด",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "ชำระเงินไม่สำเร็จ",
          text: "เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์",
        });
      }
    } else {
      console.log("Payment method not set to 'linepay'");
    }
  };
  
  

  return (
    <div className="container-taros-payment">
      <div
        className="container d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div
          className="card shadow-lg p-4"
          style={{ borderRadius: "15px", width: "100%", maxWidth: "500px" }}
        >
          <h1
            className="mb-4 text-center"
            style={{ fontSize: "24px", fontWeight: "bold" }}
          >
            ข้อมูลสำหรับการชำระเงิน
          </h1>
          <form onSubmit={handleSubmit}>
            <div
              className="form-group text-center"
              style={{ width: "100%", backgroundColor: "#ddddd" }}
            >
              <img
                src="/images/LINE-Pay(h)_W238_n.png"
                alt="Line Pay"
                style={{
                  maxWidth: "400px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
            </div>
            <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
            <div className="form-group mt-5 p-1">
              <label htmlFor="referralCode" className="font-weight-bold">
                รหัสแนะนำ (Optional เพื่อรับส่วนลด)
              </label>
              <input
                type="text"
                className="form-control"
                id="referralCode"
                placeholder="Enter referral code"
                value={referralCode}
                onChange={handleReferralCodeChange}
              />
            </div>
            {/* ปุ่มกดต่อไป */}
            <div className="d-flex justify-content-between mt-5 ">
              <button
                onClick={handleBackhome}
                className="btn btn-secondary custom-button"
                style={{ padding: "10px 20px", borderRadius: "30px"}}
              >
                ยกเลิก
              </button>
              <button type="submit" className="btn btn-primary btn-block" style={{ padding: "10px 20px", borderRadius: "30px" }}>
                ชำระเงิน
              </button>
            </div>
            <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
            <p className="text-secondary">
              <strong>การชำระเงิน:</strong>
              <br />
              ผู้ใช้สามารถชำระเงินผ่าน Line Pay
              <br />
              <a
                href="https://event-web.line.me/ecth/v2/article/kELnDnk"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary"
                onClick={(e) => {
                  e.preventDefault();
                  const isLineBrowser = /Line/i.test(navigator.userAgent);
                  if (isLineBrowser) {
                    alert(
                      "กรุณาเปิดลิงก์นี้ใน Browser หลัก เช่น Chrome หรือ Safari"
                    );
                  } else {
                    window.open(
                      "https://event-web.line.me/ecth/v2/article/kELnDnk",
                      "_blank"
                    );
                  }
                }}
              >
                วิธีเติมเงินเข้า Line Pay
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TarotPayment;
