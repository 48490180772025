import React from "react";

const RegistorComplete= () => {
      

    return (
        <div className="app d-flex align-items-center justify-content-center vh-100">
            <div className="containers p-5 text-center">
                <h2 className="mb-4">การสมัครสำเร็จ</h2>
                <p className="text-muted mb-4">โปรดรอการยืนยัน จะแจ้งไปในแชทของท่าน 1-2 วัน</p>
                <p className="text-muted mb-4">กดปุ่ม "x" ข้างบนขวามือเพื่อกลับหน้าแชท</p>
              
            </div>
        </div>
    );
};

export default RegistorComplete;
