// App.tsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BackEndManager.css";
import { debug } from "console";

interface User {
  userId: string;
  "refferal-code": string; // ต้องใช้แบบนี้สำหรับ `refferal-code`
  status: boolean;
  verify: boolean;
  "time-register": string;
  distributorType: string;
  user: {
    name: string;
    lastname: string;
    phoneNumber: string;
    address: string;
    district: string;
    province: string;
    postalCode: string;
    image_url: string;
  };
  bank: {
    bankAccount: string;
    bankName: string;
  };
}

interface Item {
  itemId: string;
  itemName: string;
  itemPrice: number;
  ownerId: string;
}

const BackEndItemManager: React.FC = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState<any[]>([]);
  const [newItem, setNewItem] = useState<Item>({
    itemId: "",
    itemName: "",
    itemPrice: 0,
    ownerId: "",
  });

  const [page, setPage] = useState<number>(1);
  const [users, setUsers] = useState<User[]>([]);
  const [refferalCode, setRefferalCode] = useState<string>("");
  const [transactions, setTransactions] = useState<any[]>([]); // สำหรับ Page 3

  const fetchUsers = () => {
    fetch("https://artelu-api.vercel.app/api/admin/backEndManager")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Fetched users:", data);
        setUsers(data);

        if (data.length > 0) {
          setRefferalCode(data[0]["refferal-code"]);
        } else {
          console.warn("No users found");
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setUsers([]);
      });
  };

  // Fetch items (existing functionality)
  const fetchItems = () => {
    fetch("https://artelu-api.vercel.app/api/admin/backEndManager/getItems")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Fetched items:", data);
        setItems(data);
      })
      .catch((error) => {
        console.error("Error fetching items:", error);
        setItems([]);
      });
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        "https://artelu-api.vercel.app/api/admin/backEndManager/get-transaction-data"
      );
      const data = response.data;

      // ถ้ามีหลายรายการ ให้จัดเรียงข้อมูลตาม `updatedAt`
      const sortedTransactions = Array.isArray(data)
        ? [...data].sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
        : [data];

      setTransactions(sortedTransactions);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]); // เคลียร์ข้อมูลในกรณีเกิดข้อผิดพลาด
    } finally {
    }
  };

  const handleSendToChat = async (transaction: any) => {
    const { userId, refferalCode, imageUrl, orderId, productType } =
      transaction;

    console.log("Preparing to send:", {
      userId,
      refferalCode,
      imageUrl,
      orderId,
      productType,
    });

    try {
      const confirmed = await Swal.fire({
        title: "ยืนยันการส่งสินค้า",
        text: "ในกรณีได้รับแจ้งจากลูกค้าที่กดซื้อสินค้าไปแล้ว แต่ระบบไม่ได้ส่งสินค้าทาง Chat Line ให้  ให้กดยืนยันเพื่อส่งสินค้า",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      });

      if (confirmed.isConfirmed) {
        const response = await axios.get(
          "https://artelu-api.vercel.app/api/admin/backEndManager/send",
          {
            params: { userId, refferalCode, imageUrl, orderId, productType },
          }
        );

        console.log("Response from API:", response.data);

        Swal.fire("สำเร็จ!", "ข้อความถูกส่งไปที่ Chat Line แล้ว", "success");
      }
    } catch (error) {
      console.error("Error sending message to chat:", error);
      Swal.fire("เกิดข้อผิดพลาด!", "ไม่สามารถส่งข้อความได้", "error");
    }
  };

  useEffect(() => {
    if (page === 1) {
      fetchUsers();
    } else if (page === 2) {
      fetchItems();
    } else if (page === 3) {
      fetchTransactions();
    }
  }, [page]);

  // Create new item
  const handleCreateItem = async () => {
    if (
      !newItem.itemId ||
      !newItem.itemName ||
      !newItem.itemPrice ||
      !newItem.ownerId
    ) {
      Swal.fire("Error", "Please fill in all fields", "error");
      return;
    }

    try {
      const confirmed = await Swal.fire({
        title: "คุณแน่ใจที่จะสร้างสินค้าใหม่?",
        text: "แน่ใจกดตกลง ไม่แน่ใจกดยกเลิก",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง, create it!",
        cancelButtonText: "ยกเลิก, cancel!",
      });

      if (confirmed.isConfirmed) {
        await axios.post(
          "https://artelu-api.vercel.app/api/admin/backEndManager/createNewItem",
          newItem
        );
        Swal.fire("Success", "Item created successfully!", "success");
        fetchItems(); // Refresh item list
      }
    } catch (error) {
      Swal.fire("Error", "กรอกข้อมูลให้ครบ", "error");
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    try {
      // แสดงการยืนยันก่อนลบ
      const confirmed = await Swal.fire({
        title: "คุณแน่ใจที่จะลบสินค้านี้?",
        text: "สินค้าจะถูกลบออกจากระบบและไม่สามารถกู้คืนได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, ลบเลย!",
        cancelButtonText: "ยกเลิก",
      });

      // ถ้าผู้ใช้ยืนยัน
      if (confirmed.isConfirmed) {
        await axios.delete(
          "https://artelu-api.vercel.app/api/admin/backEndManager/removeItem",
          {
            data: { itemId }, // ส่ง itemId ใน request body
          }
        );

        // แสดงข้อความสำเร็จ
        Swal.fire("ลบสำเร็จ!", "สินค้าถูกลบออกจากระบบแล้ว", "success");

        // ดึงรายการสินค้าล่าสุด
        fetchItems();
      }
    } catch (error) {
      // แสดงข้อความข้อผิดพลาด
      Swal.fire("ลบไม่สำเร็จ", "เกิดข้อผิดพลาดในการลบสินค้า", "error");
      console.error("Error deleting item:", error);
    }
  };

  const handleOpenEdit = (ref: string) => {
    navigate(`/backEndManager/itemManager/editUser?refferalCode=${ref}`);
  };

  const handleOpenPayment = () => {
    navigate(`/backEndManager/itemManager/payUsers`);
  };

  return (
    <div className="container mt-4 fontArtteluh mt-5">
      <h2 className="text-center mb-4">จัดการข้อมูลหลังบ้าน Artteluh</h2>
      {/* Navigation Buttons */}
      <div className="container ">
        <div className="d-flex justify-content-left mt-5">
          <button
            className={`btn mx-2 ${
              page === 1 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(1)}
          >
            ข้อมูลสมาชิก
          </button>
          <button
            className={`btn mx-2 ${
              page === 2 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(2)}
          >
            ตั้งค่าสินค้า
          </button>
          <button
            className={`btn mx-2 ${
              page === 3 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setPage(3)}
          >
            สินค้าที่ขายแล้ว
          </button>
          <button
            className={`btn mx-2 ${
              page === 4 ? "btn-primary" : "btn-secondary"
            }`}
            onClick={handleOpenPayment}
          >
            จ่านเงินสมาชิก
          </button>
        </div>

        <div className="container mt-5 p-4 bg-light">
          {/* Render Selected Page */}
          {page === 1 ? (
            <div>
              <h3>Users Management( จัดการข้อมูลผู้ใช้ )</h3>
              <div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ผู้ใช้</th>
                      <th>ชื่อ</th>
                      <th>เบอร์โทร</th>
                      <th>ที่อยู่</th>
                      <th>ธนาคาร</th>
                      <th>ส่วนแบ่ง</th>
                      <th>สมัครเมื่อ</th>
                      <th>Line</th>
                      <th>ใช้งาน</th>
                      <th>ยืนยัน</th>

                      <th>ข้อมูล</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      [...users]
                        .sort(
                          (a, b) =>
                            new Date(b["time-register"]).getTime() -
                            new Date(a["time-register"]).getTime()
                        ) // เรียงจากวันที่ล่าสุด
                        .map((user) => (
                          <tr key={user["refferal-code"]}>
                            <td>{user["refferal-code"]}</td>{" "}
                            {/* ใช้แบบนี้เพื่อเข้าถึง `refferal-code` */}
                            <td>
                              {user.user.name} {user.user.lastname}
                            </td>
                            <td>{user.user.phoneNumber}</td>
                            <td>
                              {user.user.address}, {user.user.district},{" "}
                              {user.user.province} - {user.user.postalCode}
                            </td>
                            <td>
                              {user.bank.bankName} - {user.bank.bankAccount}
                            </td>
                            <td>
                              {user.distributorType === "1"
                                ? "ตัวแทนจำหน่าย"
                                : user.distributorType === "2"
                                ? "ศิลปิน"
                                : user.distributorType === "3"
                                ? "หมอดู"
                                : user.distributorType === "0"
                                ? "Artteluh Office"
                                : "ไม่ระบุ"}
                            </td>
                            <td>
                              {new Date(user["time-register"]).toLocaleString()}
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-outline-primary ms-2"
                                onClick={() => {
                                  navigator.clipboard.writeText(user.userId);
                                  Swal.fire(
                                    "Copied!",
                                    "User ID has been copied to clipboard",
                                    "success"
                                  );
                                }}
                              >
                                Copy
                              </button>
                            </td>
                            <td>{user.status ? "Active" : "Inactive"}</td>
                            <td
                              className={`text-center border ${
                                user.verify
                                  ? "bg-success text-light"
                                  : "bg-danger text-light"
                              }`}
                            >
                              {user.verify ? "Yes" : "No"}
                            </td>
                            <td>
                              <button
                                className="btn btn-warning"
                                onClick={() =>
                                  handleOpenEdit(user["refferal-code"])
                                }
                              >
                                แก้ไข
                              </button>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">
                          No users found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* แสดงข้อมูล items แบบสด 
               <div className="mt-4">
                <h5>แสดง item แบบสด (Raw Data):</h5>
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  {JSON.stringify(users, null, 2)}
                </div>
              </div>*/}

              <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>
                <p>
                  <strong>ข้อมูลแสดง:</strong>
                  <br />
                  แสดงข้อมูลสมาชิกทั้งหมด
                </p>
                <p>
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ตรวจสอบอนุมัติสมาชิก ตัวแทนจำหน่าย ศิลปิน หมอดู..<br />
                  2.ปรับแกไขข้อมูลสมาชิก<br />
                  3.สร้างสินค้า<br />
                  4.แสดงสินค้าที่ขายได้<br />
                  5.สร้างรอบโอนเงินให้สมาชิก<br />
                </p>
              </div>
            </div>
          ) : page === 2 ? (
            <div>
              <h2>สินค้าปัจุบัน</h2>
              <hr />
              <div className="container mb-4">
                <h5 className="card-header mb-2">ช่องสร้างสินค้าใหม่</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Item ID(รหัสสินค้า)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.itemId}
                        onChange={(e) =>
                          setNewItem({ ...newItem, itemId: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Item Name(ชื่อสินค้า)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.itemName}
                        onChange={(e) =>
                          setNewItem({ ...newItem, itemName: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Item Price(ตั้งราคาขาย)</label>
                      <input
                        type="number"
                        className="form-control"
                        value={newItem.itemPrice}
                        onChange={(e) =>
                          setNewItem({
                            ...newItem,
                            itemPrice: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Owner ID(สมาชิกที่เป็นเจ้าของ)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={newItem.ownerId}
                        onChange={(e) =>
                          setNewItem({ ...newItem, ownerId: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleCreateItem}
                  >
                    Create Item(สร้างสินค้า)
                  </button>
                </div>
              </div>
              <hr />
              <div className="container cards">
                <h5 className="card-header mb-2">แสดงข้อมูลสินค้าที่มี</h5>
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item ID</th>
                        <th>Item Name</th>
                        <th>Item Price</th>
                        <th>Owner ID</th>
                        <th>สินค้าของ</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(items) &&
                        [...items]
                          .sort((a, b) => a.ownerId.localeCompare(b.ownerId)) // เรียงตาม ownerId
                          .map((item) => {
                            // หา user ที่มี refferal-code ตรงกับ ownerId
                            const matchedUser = users.find(
                              (user) => user["refferal-code"] === item.ownerId
                            );

                            return (
                              <tr key={item.itemId}>
                                <td>{item.itemId}</td>
                                <td>{item.itemName}</td>
                                <td>{item.itemPrice} บาท</td>
                                <td>{item.ownerId}</td>
                                <td>
                                  {matchedUser
                                    ? `${matchedUser.user.name} ${matchedUser.user.lastname}`
                                    : "ไม่พบข้อมูล"}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      handleDeleteItem(item.itemId)
                                    }
                                  >
                                    ลบ
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>
                <p>
                  <strong>ข้อมูลแสดง:</strong>
                  <br />
                  แสดงข้อมูลสมาชิกทั้งหมด
                </p>
                <p>
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ตรวจสอบอนุมัติสมาชิก ตัวแทนจำหน่าย ศิลปิน หมอดู..<br />
                  2.ปรับแกไขข้อมูลสมาชิก<br />
                  3.สร้างสินค้า<br />
                  4.แสดงสินค้าที่ขายได้<br />
                  5.สร้างรอบโอนเงินให้สมาชิก<br />
                </p>
              </div>
            </div>
          ) : page === 3 ? (
            <div>
              <h3>Transaction History</h3>
              {transactions.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>transec Id(รหัสขาย)</th>
                      <th>Item Id(สินค้า)</th>
                      <th>Amount(ราคา)</th>
                      <th>Product Type(รูปแบบสินค้า)</th>
                      <th>Status(สถานะ)</th>
                      <th>Updated At(เวลา)</th>
                      <th>Image(ภาพ)</th>
                      <th>Send(ส่งสินค้าอีกครั้ง)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions
                      .filter(
                        (transaction) =>
                          transaction.status && transaction.status.trim() !== ""
                      ) // กรองเฉพาะรายการที่ status ไม่ว่างหรือ null
                      .map((transaction) => (
                        <tr key={transaction.transactionId}>
                          <td>{transaction.transactionId}</td>
                          <td>{transaction.itemId}</td>
                          <td>{transaction.amount} บาท</td>
                          <td>{transaction.productType}</td>
                          <td>{transaction.status}</td>
                          <td>
                            {new Date(transaction.updatedAt).toLocaleString()}
                          </td>
                          <td>
                            <img
                              src={transaction.imageUrl}
                              alt={transaction.itemId}
                              style={{ width: "50px", height: "auto" }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-primary ms-5 p-4"
                              onClick={() => handleSendToChat(transaction)}
                            >
                              Send
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p>No transactions found.</p>
              )}
              <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>
                <p>
                  <strong>ข้อมูลแสดง:</strong>
                  <br />
                  แสดงข้อมูลสมาชิกทั้งหมด
                </p>
                <p>
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ตรวจสอบอนุมัติสมาชิก ตัวแทนจำหน่าย ศิลปิน หมอดู..<br />
                  2.ปรับแกไขข้อมูลสมาชิก<br />
                  3.สร้างสินค้า<br />
                  4.แสดงสินค้าที่ขายได้<br />
                  5.สร้างรอบโอนเงินให้สมาชิก<br />
                </p>
              </div>
            </div>
            
          ) : page === 4 ? (
            <div>
              <h3>Fortune Calendar</h3>
              <p>View and manage your calendar here...</p>
            </div>
          ) : (
            <div>
              <h3>Page Not Found</h3>
            </div>
          )}
          
        </div>
        
      </div>
    </div>
  );
};

export default BackEndItemManager;
