import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from 'react-router-dom';

const SuccessPayment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const transactionId = params.get("transactionId");


      

    return (
        <div className="app d-flex align-items-center justify-content-center vh-100">
        <div className="containers p-5 text-center">
          <h2 className="mb-4">การซื้อสินค้าสำเร็จ</h2>
          <p className="text-muted mb-4">
            สั่งซื้อสำเร็จ Artteluh Thailand<br />
            สินค้าได้ส่งไปที่ Line ของท่านแล้ว<br />
            กดปุ่ม "x" ข้างบนขวามือเพื่อกลับ
          </p>
          {transactionId && (
            <>
              <p>Transaction ID: {transactionId}</p>
            </>
          )}
        </div>
      </div>
    );
};

export default SuccessPayment;
