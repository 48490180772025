import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const adminId = localStorage.getItem("adminId");
  const adminPassword = localStorage.getItem("adminPassword");

  if (!adminId || !adminPassword) {
    return <Navigate to="/backEndManager/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
