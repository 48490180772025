import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const BackEndManager: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!id || !password) {
      Swal.fire("Error", "Please fill in all fields", "error");
      return;
    }

    try {
      const response = await axios.post(
        "https://artelu-api.vercel.app/api/admin/artteluh/login",
        { id, password }
      );

      if (response.data.success) {
        Swal.fire("Success", "Login successful!", "success");
        localStorage.setItem("adminId", response.data.id);
        localStorage.setItem("adminPassword", response.data.password);
        navigate("/backEndManager/itemManager"); // Redirect to backend
      } else {
        Swal.fire("Error", response.data.message || "Invalid credentials", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to login. Please try again.", "error");
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Admin Login</h2>
      <div className="cards p-4 bg-light p-5">
        <div className="mb-3">
          <label htmlFor="id" className="form-label">ID</label>
          <input
            type="text"
            id="id"
            className="form-control"
            value={id}
            onChange={(e) => setId(e.target.value)}
            placeholder="Enter Admin ID"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
          />
        </div>
        <button className="btn btn-primary w-100" onClick={handleLogin}>
          Login
        </button>
      </div>
      <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>

                <p className="p-2">
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ให้ใช้งานเฉพาะผู้ที่เป็น  Admin เท่านั้น ไม่อนุญาตให้บุคคลทั่วไปใช้.<br />
                  2.Admin สามารถจัดการข้อมูลตามระบบที่ทางผู้สร้างกำหนดไว้ให้.<br />
                  3.และห้ามไม่ให้เผยแพร่ลิ้งค์นี้แก่บุคคลทั่วไปที่ไม่ได้เกี่ยวข้องกับพนักงานภายในที่รับผิดชอบด้านข้อมูล.<br />
                  4.ไม่ให้เผยแพร่รหัสเข้าใช้เด็ดขาด.<br />
                  5.ห้ามแคปรูปหรือนำข้อมูลสมาชิกเผยแพร่บนสาธารณะหรือ socials เด็ดขาด.
                </p>
              </div>
    </div>
  );
};

export default BackEndManager;
