import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import liff from "@line/liff"; // Import LIFF SDK
import "./App.css"; // ใส่ฟอนต์ 'Kanit'
import axios from "axios";

interface FormDataType {
  [key: string]: any; // เพิ่ม Index Signature
  "refferal-code": string;
  bank: {
    bankAccount: string;
    bankName: string;
  };
  distributorType: string;
  password: string;
  status: boolean;
  "time-register": string;
  user: {
    address: string;
    distributorType: string;
    district: string;
    image_url: string;
    lastname: string;
    name: string;
    phoneNumber: string;
    postalCode: string;
    province: string;
  };
  userId: string;
  verify: boolean;
}

const MamberManager: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [step, setStep] = useState(1);
  const [memberData, setMemberData] = useState<any>(null);

  const [formData, setFormData] = useState<FormDataType>({
    "refferal-code": "",
    bank: {
      bankAccount: "",
      bankName: "",
    },
    distributorType: "",
    password: "",
    status: true,
    "time-register": "",
    user: {
      address: "",
      distributorType: "",
      district: "",
      image_url: "",
      lastname: "",
      name: "",
      phoneNumber: "",
      postalCode: "",
      province: "",
    },
    userId: "",
    verify: true,
  });

  const [memberId, setMemberId] = useState("");
  const [memberPass, setMemberPass] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]); // สำหรับ Page 3
  const [totalIncome, setTotalIncome] = useState<number>(0);

  useEffect(() => {
    //setUserId("U839e3bcb8632fcaea77aff9e03b06d5e");
    liff.init({ liffId: '2006373201-PbD918Xx' }) // ใส่ LIFF ID ของคุณ
        .then(() => {
          if (liff.isLoggedIn()) {
            liff.getProfile().then(profile => {
              setUserId(profile.userId); // เก็บ userId ลงใน state
             // loginFetchMemberData(); //login by userId
            }).catch(err => {
              console.error('Error getting profile: ', err);
              Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถรับข้อมูลผู้ใช้ได้', 'error');
            });
          } else {
            liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
          }
        })
        .catch(err => {
          console.error('LIFF initialization failed: ', err);
          Swal.fire('เกิดข้อผิดพลาด', 'การเริ่มต้น LIFF ล้มเหลว', 'error');
        });

    //setIsLoggedIn(true);
  }, []);

  const loginFetchMemberData = () => {
    // ตรวจสอบว่า memberId และ memberPass มีค่าหรือไม่
    if (!userId) {
      Swal.fire({
        icon: "warning",
        title: "ไม่ได้เข้าสู้ระบบ",
        text: "กรุณากรอก  Login Line",
      });
      return; // หยุดการทำงานของฟังก์ชัน
    }

    // แสดงสถานะการโหลด
    Swal.fire({
      title: "Loading...",
      text: "กำลังโหลดข้อมูล กรุณารอสักครู่",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    fetch(`https://artelu-api.vercel.app/api/member/profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        referralCode: memberId,
        password: userId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("ข้อมูล Id หรือ Password ไม่ถูกต้อง");
        }
        return response.json();
      })
      .then((data) => {
        // ปิดการแสดง Loading
        Swal.close();

        // แจ้งผลสำเร็จ
        Swal.fire({
          icon: "success",
          title: "สำเร็จ!",
          text: "เข้าสู่ระบบ",
        });

        // console.log("Member Data:", data);
        // ใส่ data ที่ดึงมาใน state memberData
        setMemberData(data);

        setIsLoggedIn(true);
        // ใช้ข้อมูลในแอปพลิเคชัน
      })
      .catch((error) => {
        // ปิดการแสดง Loading
        Swal.close();

        // แจ้งข้อผิดพลาด
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: error.message || "ไม่สามารถโหลดข้อมูลได้",
        });

        console.error("Error fetching member data:", error);
      });
  };

  const fetchTransactions = async () => {
    try {
      const referralCode = memberData?.[0]?.["refferal-code"]; // ดึง referralCode จาก memberData
      if (!referralCode) {
        console.warn("Referral Code not found in memberData");
        setTransactions([]);
        return;
      }

      let response; // ตัวแปรสำหรับเก็บผลลัพธ์ API

      // ตรวจสอบประเภท distributorType และเรียก API ที่เหมาะสม
      if (memberData[0]?.["distributorType"] === "1") {
        console.log("ตัวแทน");
        response = await axios.get(
          "https://artelu-api.vercel.app/api/member/profile/getItem/item",
          {
            params: {
              referralCode: referralCode, // ใช้ referralCode เป็น transactionId
            },
          }
        );
      } else if (memberData[0]?.["distributorType"] === "2") {
        console.log("ศิลปิน");
        response = await axios.get(
          "https://artelu-api.vercel.app/api/member/profile/getItem",
          {
            params: {
              referralCode, // ส่ง referralCode ไปใน query parameter
            },
          }
        );
      } else if (memberData[0]?.["distributorType"] === "3") {
        console.log("หมอดู");
        response = await axios.get(
          "https://artelu-api.vercel.app/api/member/profile/getItem",
          {
            params: {
              referralCode, // ส่ง referralCode ไปใน query parameter
            },
          }
        );
      } else {
        console.warn("Distributor type not recognized.");
        setTransactions([]);
        return;
      }

      // ตรวจสอบ response และเก็บข้อมูล
      const data = response?.data;
      if (!Array.isArray(data)) {
        console.warn("Unexpected data format:", data);
        setTransactions([]);
        return;
      }

      // จัดเรียงข้อมูลตาม updatedAt (กรณีมี field updatedAt)
      const sortedTransactions = data.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });

// รวบรวมรายได้ทั้งหมดตาม distributorType
const totalIncome = sortedTransactions.reduce((acc, transaction) => {
  const amount = transaction.amount || 0; // ตรวจสอบค่า amount
  let calculatedAmount = 0;

  if (memberData[0]?.["distributorType"] === "1") {
    calculatedAmount = amount * 0.1; // 10% สำหรับ Distributor Type 1
  } else if (memberData[0]?.["distributorType"] === "2" || memberData[0]?.["distributorType"] === "3") {
    calculatedAmount = amount * 0.25; // 25% สำหรับ Distributor Type 2 และ 3
  }

  return acc + calculatedAmount; // เพิ่มผลลัพธ์ที่คำนวณแล้ว
}, 0);

console.log("Total Income:", totalIncome); // ตรวจสอบค่ารายได้รวม

      // อัปเดต state
      setTotalIncome(totalIncome); // เก็บรายได้รวม
      setTransactions(sortedTransactions); // เก็บรายการข้อมูล
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]); // เคลียร์ข้อมูลในกรณีเกิดข้อผิดพลาด
    }
  };

  // Initialize LIFF และดึง userId
  /* useEffect(() => {
    liff.init({ liffId: '2006373201-Y0pwJ7Z9' }) // ใส่ LIFF ID ของคุณ
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then(profile => {
            setUserId(profile.userId);  // เก็บ userId ลงใน state
            
          }).catch(err => {
            console.error('Error getting profile: ', err);
          });
        } else {
          liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
        }
      })
      .catch(err => {
        console.error('LIFF initialization failed: ', err);
      });
  }, []);*/

  const updateFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name.includes(".")) {
      const [parentKey, childKey] = name.split(".");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [parentKey]: {
          ...(prevFormData[parentKey] as Record<string, any>),
          [childKey]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  return (
    <div className="container mt-5 mb-5">
      {!isLoggedIn ? (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="cards p-4 shadow">
              <h2 className="text-center mb-4">
                Member (เข้าใช้งานผู้แบ่งรายได้)
              </h2>
              <div className="mb-3">
                <label>ID:</label>
                <input
                  type="text"
                  className="form-control"
                  value={memberId}
                  onChange={(e) => setMemberId(e.target.value)}
                />
              </div>

              <button
                onClick={loginFetchMemberData}
                className="btn btn-primary w-100"
              >
                Login
              </button>
            </div>
            <div className="col-md-12 mt-5 text-secondary">
              <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
              <p className="font-weight-bold">อ่านการใช้งาน</p>
              <p>
                <strong>การเข้าใช้งาน:</strong>
                <br />
                เอาข้อมูล ID ที่ทางเราส่งให้ผ่าน Chat Line
                <br />
                ใส่ไปที่ช่อง ID ให้ถูกต้อง (เป็นตัวอักษรใหญ่ทั้งหมด)
              </p>
              <p>
                <strong>ข้อมูลแสดง:</strong>
                <br />
                ในส่วนบริการสำหรับผู้เป็นสมาชิก
                จะแสดงข้อมูลสินค้าของสมาชิกที่ขายได้และยอด
              </p>
              <p>
                <strong>การโอน:</strong>
                <br />
                รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15
                ของแต่ละเดือน(กรณีตรงกับวัดหยุดจะเลื่อนไปวันทำการ)
                <br />
                ข้อกำหนดการโอนเงิน ทางบริษัท ArtVentureNFT จะเป็นผู้จัดแจง
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="cards p-4 shadow">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">
                  Profile ID: {memberData[0]?.["refferal-code"]}
                </h5>
                <button className="btn btn-outline-secondary btn-sm">
                  {memberData[0]?.["distributorType"] === "1"
                    ? "ตัวแทนจำหน่าย"
                    : memberData[0]?.["distributorType"] === "2"
                    ? "ศิลปิน"
                    : memberData[0]?.["distributorType"] === "3"
                    ? "หมอดู"
                    : "ไม่ระบุ"}
                </button>
              </div>
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    ชื่อ: {memberData[0]?.user?.name}{" "}
                    {memberData[0]?.user?.lastname}
                  </span>
                  <span className="text-success">active</span>
                </div>
                <div>
                  {/*  <button className="btn btn-link btn-sm p-0">edit</button>*/}
                </div>
              </div>
              <hr />
              <h6 className="mb-3">Income</h6>
              {transactions.length > 0 ? (
                <p>รายได้: {totalIncome} บาท</p>
              ) : (
                <p>รายได้: กดโหลด</p>
              )}

              <hr />
              <h6 className="mb-3">Total</h6>
              <div className="bg-light p-3 rounded">
                {/* Total content goes here */}
                <div className="">
                  <h3>Transaction History</h3>
                  {transactions.length > 0 ? (
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>ยอด</th>
                            <th>สินค้า</th>
                            <th>ขายออก(เวลา)</th>
                          </tr>
                        </thead>
                        <tbody className="text-table-items-paid">
                          {transactions.map((transaction) => {
                            // Debugging logs
                            console.log("Transaction:", transaction);
                            console.log(
                              "Transaction Amount:",
                              transaction.amount
                            );
                            console.log(
                              "Distributor Type:",
                              memberData[0]?.["distributorType"]
                            );

                            const amount = parseFloat(transaction.amount) || 0; // แปลงเป็นตัวเลข
                            let calculatedAmount = 0;

                            // ใช้ distributorType จาก formData ในการสร้างเงื่อนไข
                            if (memberData[0]?.["distributorType"] === "1") {
                              calculatedAmount = amount * 0.1; // 10% สำหรับ Distributor Type 1
                            } else if (
                              memberData[0]?.["distributorType"] === "2" ||
                              memberData[0]?.["distributorType"] === "3"
                            ) {
                              calculatedAmount = amount * 0.25; // 25% สำหรับ Distributor Type 2 และ 3
                            }

                            return (
                              <tr key={transaction.transactionId}>
                                <td>{calculatedAmount.toFixed(2)} บาท</td>
                                <td>{transaction.productType}</td>{" "}
                                {/* แสดง distributorType */}
                                <td>
                                  {new Date(
                                    transaction.updatedAt
                                  ).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        <div className="col-md-12 mt-5 text-secondary">
                          <p className="border-top my-3"></p>{" "}
                          {/* เส้นขั้นบรรทัด */}
                        </div>
                      </table>
                      <div className="col-md-12 mt-5 text-secondary">
                        <p>
                          <p className="font-weight-bold">อ่านการใช้งาน</p>
                          <strong>ข้อมูลแสดง:</strong>
                          <br />
                          ในส่วนบริการสำหรับผู้เป็นสมาชิก
                          จะแสดงข้อมูลสินค้าของสมาชิกที่ขายได้และยอด
                        </p>
                        <p>
                          <strong>การโอน:</strong>
                          <br />
                          รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15
                          ของแต่ละเดือน(กรณีตรงกับวัดหยุดจะเลื่อนไปวันทำการ)
                          <br />
                          ข้อกำหนดการโอนทางบริษัท ArtVentureNFT จะเป็นผู้จัดแจง
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center text-muted">
                      <p>ไม่มีข้อมูลการขายสินค้า หรือกด Load.</p>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={fetchTransactions}
                      >
                        Load
                      </button>
                      <div className="col-md-12 mt-5 text-secondary">
                        <p className="border-top my-3"></p>{" "}
                        {/* เส้นขั้นบรรทัด */}
                        <p className="font-weight-bold">อ่านการใช้งาน</p>
                        <p>
                          <strong>ข้อมูลแสดง:</strong>
                          <br />
                          ในส่วนบริการสำหรับผู้เป็นสมาชิก
                          จะแสดงข้อมูลสินค้าของสมาชิกที่ขายได้และยอด
                        </p>
                        <p>
                          <strong>การโอน:</strong>
                          <br />
                          รอบโอนเงินจะมี 2 วัน คือ วันที่ 1 และวันที่ 15
                          ของแต่ละเดือน(กรณีตรงกับวัดหยุดจะเลื่อนไปวันทำการ)
                          <br />
                          ข้อกำหนดการโอนทางบริษัท ArtVentureNFT จะเป็นผู้จัดแจง
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MamberManager;
