import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
    const navigate = useNavigate();


      const handleCloseLiff = () => {

      };
      

    return (
        <div className="app d-flex align-items-center justify-content-center vh-100">
            <div className="containers p-5 text-center">
                <h2 className="mb-4">การชำระเงินถูกยกเลิก</h2>
                <p className="text-muted mb-4">โปรดกลับไปหน้าแชทเพื่อเลือกรายการใหม่</p>
                <p className="text-muted mb-4">กดปุ่ม "x" ข้างบนขวามือเพื่อกลับหน้าแชท</p>
              
            </div>
        </div>
    );
};

export default CancelPage;
