import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type TransactionData = {
  transactionId: string;
  orderId: string;
  userId: string;
  refferalCode: string;
  imageUrl: string;
  amount: string;
  productType: string;
  itemId: string;
  status: string;
  updatedAt: string;
};

const ConfirmPage: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [transactionData, setTransactionData] = useState<TransactionData | null>(null);
    const [loading, setLoading] = useState(true);
    const [transactionIds, setTransactionIds] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [error, setError] = useState<string | null>(null);
  
    const fetchTransactionData = () => {
      setLoading(true);
      setError(null);
  
      const params = new URLSearchParams(location.search);
      const orderId = params.get("orderId") || "";
      const keepTransactionIdc = params.get("transactionId") || "";
      setTransactionIds(keepTransactionIdc);
      setTransactionId(orderId);
  
      /*if (orderId) {
        fetch(`https://artelu-api.vercel.app/api/get-transaction-data?transactionId=${orderId}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Failed to fetch transaction data");
            }
            return response.json();
          })
          .then((data) => {
            setTransactionData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching transaction data:", error);
            setError("ไม่พบข้อมูลการทำธุรกรรม");
            setLoading(false);
          });
      } else {
        setError("ไม่พบ Order ID");
        setLoading(false);
      }*/
    };
  
    useEffect(() => {
      fetchTransactionData();
    }, []);
  
    const handleConfirmPayment = () => {
      if (!transactionIds) return;
  
      Swal.fire({
        title: "กำลังยืนยัน...",
        text: "โปรดรอสักครู่",
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  


  
      fetch("https://artelu-api.vercel.app/api/confirm-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ transactionIds, transactionId}),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to confirm payment");
          }
          return response.json();
        })
        .then(() => {
          Swal.close();
          navigate(`/successPayment?transactionId=${transactionId}`);
        })
        .catch((error) => {
          console.error("Error confirming payment:", error);
          setError("ไม่สามารถยืนยันการชำระเงินได้");
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ไม่สามารถยืนยันการชำระเงินได้",
          });
        });
    };
  
    const handleCancelPayment = () => {
      window.location.href = "https://www.artteluh.com/cancel";
    };
  
  
    return (
      <div className="app d-flex align-items-center justify-content-center vh-100">
        <div className="containers p-5 text-center">
          <h2 className="mb-4">ยืนยันการสั่งซื้อ</h2>
          <p className="text-muted mb-4">ยืนยันการสั่งซื้อสินค้า Artteluh Thailand</p>
          {transactionIds && (
            <>
              <p>Transaction ID: {transactionId}</p>
            </>
          )}
          <button onClick={handleConfirmPayment} className="btn btn-success mt-3 mx-2">
            ยืนยัน
          </button>
          <button onClick={handleCancelPayment} className="btn btn-danger mt-3 mx-2">
            ยกเลิก
          </button>
        </div>
      </div>
    );
  };
  
  export default ConfirmPage;
  