import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./tarotHome.css";
import liff from "@line/liff";
import Swal from "sweetalert2";

const TarotHome: React.FC<{
  formData: any;
  updateFormData: (data: any) => void;
}> = ({formData, updateFormData}) => {
  const navigate = useNavigate(); // Initialize navigate function
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [userId, setUserId] = useState<string | null>(null); // เก็บ userId จาก LIFF
  const [imageLoading, setImageLoading] = useState({
    titleText: true,
    mainImage: true,
    buyButton: true,
  }); // สถานะการโหลดแต่ละภาพ


  

  

  const handleOpenCardClick = () => {
    if (userId) {
      navigate("/tarot/aday"); // Navigate to the desired route if logged in
    } else {
      Swal.fire({
        icon: "warning",
        title: "กรุณาเข้าสู่ระบบ",
        text: "คุณต้องเข้าสู่ระบบเพื่อใช้ฟีเจอร์นี้",
        confirmButtonText: "ตกลง",
      });
    }
  };

  useEffect(() => {
    liff.init({ liffId: '2006373201-B7M7Zo5J' }) // ใส่ LIFF ID ของคุณ
    .then(() => {
      if (liff.isLoggedIn()) {
        liff.getProfile().then(profile => {
          setUserId(profile.userId); // เก็บ userId ลงใน state
          updateFormData({ userId: profile.userId }); // ส่ง userId เข้า formData
        }).catch(err => {
          console.error('Error getting profile: ', err);
          Swal.fire('เกิดข้อผิดพลาด', 'ไม่สามารถรับข้อมูลผู้ใช้ได้', 'error');
        });
      } else {
        liff.login();  // ถ้ายังไม่ได้ล็อกอิน ให้พาไปที่หน้าล็อกอิน
      }
    })
    .catch(err => {
      console.error('LIFF initialization failed: ', err);
      Swal.fire('เกิดข้อผิดพลาด', 'การเริ่มต้น LIFF ล้มเหลว', 'error');
    });

    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas is not available");
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("Canvas context is not available");
      return;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particlesArray: Particle[] = [];
    const numParticles = 5;

    class Particle {
      x: number;
      y: number;
      speedX: number;
      speedY: number;
      size: number;
      opacity: number;

      constructor() {
        // Use the `canvas!` operator to assert that `canvas` is not null
        this.x = Math.random() * (canvas?.width ?? 0);
        this.y = Math.random() * (canvas?.height ?? 0);
        this.speedX = -Math.random() * 3 - 1;
        this.speedY = Math.random() * 3 + 1;
        this.size = Math.random() * 3 + 1;
        this.opacity = Math.random();
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x < 0 || this.y > (canvas?.height ?? 0)) {
          this.x = Math.random() * (canvas?.width ?? 0);
          this.y = 0;
          this.speedX = -Math.random() * 3 - 1;
          this.speedY = Math.random() * 3 + 1;
        }
      }

      draw() {
        if (!context) return; // Double-check context is available
        context.beginPath();
        context.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
        context.fill();
      }
    }

    for (let i = 0; i < numParticles; i++) {
      particlesArray.push(new Particle());
    }

    const animate = () => {
      if (!context) return; // Ensure context is valid
      context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);

      particlesArray.forEach((particle) => {
        particle.update();
        particle.draw();
      });

      requestAnimationFrame(animate);
    };

    animate();

    return () => {
      // Cleanup function
      if (context) {
        context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);
      }
    };
  }, []);


  return (
    <div className="container-taros">
      {/* Background Image */}
      <canvas ref={canvasRef} className="particle-canvas" />
      <motion.div
                className="background-card"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
      
      
        <div className="content-overlay">
          {/* Daily Reading Text */}
          <h5 className="">ดูดวงรายวัน</h5>

          {/* Button */}
          <button
            className="open-card-button"
            onClick={handleOpenCardClick} // Call the handler function on click
          >
            เปิดไพ่
          </button>
        </div>
        </motion.div>
    </div>
  );
};

export default TarotHome;
