export interface TarotCard {
    id: number;
    name: string;
    description: string;
    image: string; // Add an image property
    artist: string; // Add an artist property
    itemId: string; // Add an item
  }
  
  export const tarotDeck: TarotCard[] = [
    { id: 1, name: "The Fool", description: "ท่านใดเปิดไพ่ The Fool  ไพ่ใบนี้ปรากฏขึ้นมา แสดงถึงการเริ่มต้นใหม่ ชอบการเรียนรู้เผชิญ การเปลี่ยนแปลอย่างตั้งมั่น ดั่งแสงอาทิตย์ สาดส่อง ถึงแม้จะมีเมฆ บดบัง แสงแห่งความงดงามของภายในจะเฉิดฉาย ทุกสิ่งอย่างทำแต่พอดีพองาม", image: "/images/tators/v1/tr_1.jpg", artist:"สมภพ กวานห้อง", itemId:"art1_0"},
    { id: 2, name: "The Magician", description: "ท่านใดเปิดไพ่  The Magician ไพ่ใบนี้ปรากฏขึ้นมา ท่านเหมือนมีพลังเวทย์มนต์ เสกเรื่องในหัวออกมาได้อย่าง เป็นรูปธรรม แต่ต้องอาศัยเหตุปัจจัย บุคคลรอบข้างเพื่อให้ร่วมแรงร่วมใจ ที่เราสร้างให้สำเร็จขึ้นมา", image: "/images/tators/v1/tr_2.jpg", artist:"สมภพ กวานห้อง", itemId:"art1_1"},
    { id: 3, name: "The High Priestess", description: "ท่านใดเปิดไพ่ THE HIEROPHANT  ไพ่ใบนี้ปรากฏขึ้นมามีความรอบรู้และจัดการสิ่งต่างๆได้อย่างดี ท่านที่หวังถึงความสุขอบอุ่นของครอบครัว สมาชิกในบ้านจะสมานฉันท์", image: "/images/tators/v1/tr_3.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_2"},
    { id: 4, name: "The Empress", description: "ท่านใดเปิดไพ่ The Emperess ไพ่ใบนี้ปรากฏขึ้นมาทรัพย์สินเงินทอง และโอกาสมากมายที่เราเคยถวิลหา จะมีโอกาสมากมายที่เราจะได้รับอย่างมีความสุขสมหวังสะดวกสบาย การที่จะหาความอุดสมสมบูรณ์นั้น ย่อมเป็นที่น่าพอใจ", image: "/images/tators/v1/tr_4.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_3"},
    { id: 5, name: "The Emperor", description: "ท่านใดเปิดไพ่ The Emperor ไพ่ใบนี้ปรากฏขึ้นมาท่านจะมีองค์ความรู้และอำนาจเสน่ห์ดึงดูดแล้วมีความเสียสละ ชอบจัดการวางแผน ให้เกิดสิ่งใหม่ๆขึ้นมา เป็นที่ภาคภูมิใจของตัวเอง และผู้อื่น", image: "/images/tators/v1/tr_5.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_4"},
    { id: 6, name: "The Hierophant", description: "ท่านใดเปิดไพ่  The Hierophant  ไพ่ใบนี้ปรากฏขึ้นมาแสดงถึงความเป็นปราชญ์ ผู้รอบรู้ ตรรกะกระบวนการคิด วิเคราะห์สิ่งต่างๆ ซึ่งสามารถชี้แนะ สอนสิ่งต่างๆที่ตัวเองให้เกิด ประโยชน์กับบุคคลได้อย่างดี", image: "/images/tators/v1/tr_6.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_5"},
    { id: 7, name: "The Lovers", description: "ท่านใดเปิดไพ่ The Lovers ไพ่ใบนี้ปรากฏขึ้นมา ไพ่แห่งความรัก ความเด็จเดี่ยวความศรัทธา ท่านที่มีความรักหรือกำลังมีความรัก เปรียบเสมือนดอกไม้สีชมพูที่กำลังผลิดอกแรกแย้ม ส่งกลิ่มหอมฟุ้งกระจาย ทำให้บุคคลที่อยู่สภาวะ มีความสุขสดชื่น", image: "/images/tators/v1/tr_7.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_6"},
    { id: 8, name: "The Chariot", description: "ท่านใดเปิดไพ่ The Chariot  ไพ่ใบนี้ปรากฏขึ้นมา ปักหมุดที่จะสำเร็จ คิดที่จะทำแล้ว ลงมืออย่างจริงจัง เหมือนม้าศึกที่ไม่เกรงกลัวต่อระยะทางและหอกแหลมคม", image: "/images/tators/v1/tr_8.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_7"},
    { id: 9, name: "Strength", description: "ท่านใดเปิดไพ่ The Strength ไพ่ใบนี้ปรากฏขึ้นมา คือตัวแทนสุภาพสตรี สุดแกร่งสุดทน สิ่งได้ที่เธอ คิดแล้วต้องทำ การจัดการของเธอ เป็นที่ยอมรับของบุคคลที่ร่วมงานกับเธอเป็นที่น่าไว้วางใจ", image: "/images/tators/v1/tr_9.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_8"},
    { id: 10, name: "The Hermit", description: "ท่านใดเปิดไพ่ THE HERMIT  ไพ่ใบนี้ปรากฏขึ้นมาสดับรับฟังเสียงตัวเอง ให้อยู่กับความสันโดด พักจากเรื่องภายนอกหันมาวิเคราะห์สิ่งต่างๆเราควรปรับปรุง สื่อสัตย์กับสิ่งที่เป็นมันจะเปลี่ยนแปลงในทางที่ดี", image: "/images/tators/v1/tr_10.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_9"},
    { id: 11, name: "Wheel of Fortune", description: "ท่านใดเปิดไพ่ The Wheel of Fortune ไพ่นี้ปรากฏขึ้นมาชะตาชีวิตนั้น ย่อมมีการเปลี่ยนแปลง เหมือนสายน้ำ  กระทบโขดหินกีดขวางน้อยใหญ่เป็นเรื่องธรรมดา ของชีวิตที่ต้องเผชิญ", image: "/images/tators/v1/tr_11.jpg" , artist:"สมภพ กวานห้อง", itemId:"art1_10"},
   
    { id: 12, name: "Justice", description: "ท่านใดเปิดไพ่ Justice ไพ่นี้ปรากฏขึ้นมา ผู้รักษาความยุติธรรมและกฎเกณฑ์ของสวรรค์และโลก ต้องใช้เหตุผลเป็นตัวตัดสิน ห้ามใช้อารมณ์เด็ดขาด ถึงเวลาที่ต้องหาจุดสมดุล แล้วเปลี่ยนตัวเองให้ได้จะดี", image: "/images/tators/v1/tr_12.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_11"},
    { id: 13, name: "The Hanged Man", description: "ท่านใดเปิดไพ่ THE Hanged Man ไพ่นี้ปรากฏขึ้นมา ต้องมีความอดทน อยู่กับสิ่งใดสิ่งหนึ่งอย่างจำใจหรือจำยอม แบบหลีกเลี่ยงไม่ได้ซึ่งการอดทนในครั้งนี้อาจจะมี  จุดสิ้นสุด แล้วหนทางที่ดีขึ้นกว่าเดิม", image: "/images/tators/v1/tr_13.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_12"},
    { id: 14, name: "Death", description: "ท่านใดเปิดไพ่ Death ไพ่นี้ปรากฏขึ้นมาการจบจากสิ่งหนึ่งและเตรียมพร้อมที่จะเริ่มต้นสู่สิ่งใหม่", image: "/images/tators/v1/tr_14.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_13"},
    { id: 15, name: "Temperance", description: "ท่านใดเปิดไพ่ Temperance ไพ่นี้ปรากฏขึ้นมา ต้องเกิดการรับมือเปลี่ยนแปลงไปตามสภาวะ ยอมรับและทำความเข้าใจถึงจะพบกับความสำเร็จ", image: "/images/tators/v1/tr_15.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_14"},
    { id: 16, name: "The Devil", description: "ท่านใดเปิดไพ่ The Devil ไพ่นี้ปรากฏขึ้นมาจงมีสติ อย่าความลุ่มหลง งมงาย มัวเมาในบางสิ่งซึ่งคุณอาจจะรู้ตัวหรือไม่รู้ตัว", image: "/images/tators/v1/tr_16.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_15"},
    { id: 17, name: "The Tower", description: "เขาพระสุเมรุเอนทรุด เมื่อรามสูรสู้รบกับพระอรชุน ใช้ปัญญาแก้ไข แม้จะเจอปัญหารอบด้าน ให้มองมุมอื่นๆที่ขาดตกบกพร่อง นำมาปรับปรุง พัฒนาตนเอง ", image: "/images/tators/v1/tr_17.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_16"},
    { id: 18, name: "The Star", description: "ท่านใดเปิดไพ่ The Star ไพ่นี้ปรากฏขึ้นมาอาจได้รับโอกาสทางการเงินที่ดี การลงทุนมีโอกาสได้ผลตอบแทนที่ดี", image: "/images/tators/v1/tr_18.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_17"},
    { id: 19, name: "The Moon", description: "ท่านใดเปิดไพ่ The Moon ไพ่นี้ปรากฏขึ้นมา แม้มีความวิตกกังวล ความเศร้า คิดมาก ไม่มั่นใจในตัวเอง หวาดกลัวอนาคตที่ยังคาดเดาไม่ได้ สับสน ขอจงมีความเชื่อมั่นในตนเอง จะก้าวข้ามสิ่งร้ายในชีวิตไปได้", image: "/images/tators/v1/tr_19.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_18"},
    { id: 20, name: "The Sun", description: "ท่านใดเปิดไพ่ The Sun ไพ่นี้ปรากฏขึ้นมา จะได้รับผลตอบแทนจากการลงทุนหรือการทำงานที่ได้ทำไว้ ความมั่นคงทางการเงินจะนำมาซึ่งความสุขและความพึง พอใจ", image: "/images/tators/v1/tr_20.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_19"},
    { id: 21, name: "Judgement", description: "ท่านใดเปิดไพ่ Judgment ไพ่นี้ปรากฏขึ้นมาบางสิ่งบางอย่างกำลังจะจบลง จะมีการเริ่มต้นใหม่ สิ่งที่ปกปิดไว้จะถูกเปิดเผยโดยสิ่งที่จะเกิดขึ้นล้วนเป็นผลมาจากการกระทำของเราเอง", image: "/images/tators/v1/tr_21.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_20"},
    { id: 22, name: "The World", description: "ท่านใดเปิดไพ่ The World ไพ่นี้ปรากฏขึ้นมา เป็นเทพแห่งพื้นแผ่นดิน เป็นจุดก่อเกิดสรรพสิ่งทั้งปวงในโลกและสัญลักษณ์แห่งความอุดมสมบูรณ์ ความสำเร็จและการบรรลุเป้าหมายที่คุณตั้งไว้ ส่งผลให้เป็นช่วงเวลาที่จะได้เป็นอิสระ มีความสุข มีความภูมิใจใน", image: "/images/tators/v1/tr_22.jpg" , artist:"เอกชัย  มิลินทะภาส",itemId:"art1_21"},
    
    { id: 23, name: "Ace of Cups", description: "ท่านใดเปิดไพ่ Ace of Cup ไพ่นี้ปรากฏขึ้นมา วันนี้อาจเป็นการเริ่มต้นใหม่ในหลายๆเรื่อง ทั้งอารมณ์ความรู้สึก ความสัมพันธ์โครงการใหม่วิธีคิดใหม่ๆ ในการพัฒนาตนเอง รู้สึกมีความสุขในรักและมิตรภาพที่ดี", image: "/images/tators/v1/tr_23.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_22"},
    { id: 24, name: "Two of Cups", description: "ท่านใดเปิดไพ่ Two of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้เรื่องความสัมพันธ์ ความรักจะดีมาก อาจมีคนรักที่จริงใจ มีความซื่อสัตย์ให้กัน อาจถึงขั้นขอแต่งงาน หรือขอเป็นแฟนถ้าเรื่องความรักจะเป็นไพ่ที่ดีที่สุด หรือหมายถึงการทำสัญญาผูกมัด แต่งงาน", image: "/images/tators/v1/tr_24.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_23"},
    { id: 25, name: "Three of Cups", description: "ท่านใดเปิดไพ่ Three of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้คุณอาจได้พบปะเพื่อนฝูง หรือได้ร่วมงานกิจกรรมต่างๆในสถานที่ต่างๆ  อาจได้เฉลิมฉลองงานเลี้ยงงานรื่นเริงความสุขที่เกิดขึ้นจากการมีส่วนร่วมในเรื่องต่างๆ", image: "/images/tators/v1/tr_25.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_24"},
    { id: 26, name: "Four of Cups", description: "ท่านใดเปิดไพ่ Four of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้คุณอาจจะรู้สึกเบื่อหน่ายคนรอบข้าง ไม่พึงพอใจกับสิ่งใดๆ  ดูเหมือนว่า อะไรๆก็ไม่ได้ดั่งใจไปซะทุกอย่าง หงุดหงิด วุ่นวายใจ กับสิ่งที่ทำอยู่ประจำวัน  เกิดจากการทำงานจนเหนื่อยล้าอ่อนเพลีย", image: "/images/tators/v1/tr_26.jpg" , artist:"เอัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_25"},
    { id: 27, name: "Five of Cups", description: "ท่านใดเปิดไพ่ Five  of Cups  ไพ่นี้ปรากฏขึ้นมา วันนี้อาจจะรู้สึกบางอย่างติดค้างในใจ เป็นทุกข์กับเรื่องในอดีต จนมองไม่เห็นโอกาสดีๆในปัจจุบันใช้สติปัญญาข้างในพิจารณาถึงเหตุและผลของสิ่งต่างๆ อย่างรอบคอบก่อนตัดสินใจ", image: "/images/tators/v1/tr_27.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_26"},
    { id: 28, name: "Six of Cups", description: "ท่านใดเปิดไพ่ Six of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้จะมีผลพวงที่คุณทำในอดีตที่ปิดบังซ่อนเร้นเป็นความลับ อาจเป็นเรื่องที่ลืมไปแล้วด้วยซ้ำ การแก้ไขความผิดพลาดที่เกิดขึ้นมาในอดีต คนรักเก่า งานเก่าๆ เรื่องเก่า ในอดีตจะกลับให้คิดให้แก้ไขอีกครั้ง", image: "/images/tators/v1/tr_28.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_27"},
    { id: 29, name: "Seven of Cups", description: "ท่านใดเปิดไพ่ Seven of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้สิ่งที่เคยได้กระทำสะสม ทั้งในเรื่องที่ดีและไม่ดี อาจส่งผลลัพธ์ให้ได้เห็นให้ได้พิสูจน์ ใช้สติปัญญาพิจารณา ถึงผลลัพธ์ที่เกิดขึ้นอาจจะมีความสุขสมหวัง หรือ อาจจะมีปัญหาที่ตามมาจากผลแห่งการกระทำในครั้งอดีต", image: "/images/tators/v1/tr_29.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_28"},
    { id: 30, name: "Eight of Cups", description: "ท่านใดเปิดไพ่ Eight of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้อาจอารมณ์หงุดหงิดใจร้อน รุ่มเร้า ร้อนรน กระวนกระวายใจ ไม่ได้ดั่งใจ ความขัดแย้ง ถกเถียงโต้แย้ง เกิดความทุกข์ เกิดการทะเลาะเบาะแว้ง ให้ใจเย็นๆสงบสติอารมณ์ให้ดีก่อน", image: "/images/tators/v1/tr_30.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_29"},
    { id: 31, name: "Nine of Cups", description: "ท่านใดเปิดไพ่ Nine of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้ปัญหาที่ค้างคาใจอยู่ อาจจะถูกคลี่คลาย แค่ยอมรับผลของการกระทำ อย่างตรงไปตรงมา ให้อภัยตนเองและผู้อื่นในสิ่งที่เคยผิดพลาด แก้ไขและเรียนรู้ที่จะลดอัตราตัวตน เพื่อส่วนรวมต่อครอบครัวและผู้อื่น", image: "/images/tators/v1/tr_31.jpg", artist:"อัครวุทธ โรจน์อังคณาวุฒิ" ,itemId:"art1_30"},
    { id: 32, name: "Ten of Cups", description: "ท่านใดเปิดไพ่ Ten  of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้เป็นวันแห่งความสำเร็จ ทุกอย่างราบรื่นลงตัว เพราะคุณมีความมั่นใจในการกระทำของตน เป็นที่ยอมรับของผู้คนมากมาย  สิ่งที่ควรทำสะสมความไว้วางใจจากผู้คน", image: "/images/tators/v1/tr_32.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_31"},
    { id: 33, name: "Page of Cups", description: "ท่านใดเปิดไพ่ Page of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้อาจจะมีการเริ่มต้นใหม่ทางด้าน ความรู้สึก ความสัมพันธ์ โครงการใหม่ แผนใหม่ ความคิดใหม่ๆ มีการพัฒนาตนเอง ประสบการณ์ใหม่ๆ ถ้าต้องตัดสินใจบาง หยุดนิ่งและพิจารณาอย่างรอบคอบ", image: "/images/tators/v1/tr_33.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_32"},
    { id: 34, name: "Knight of Cups", description: "ท่านใดเปิดไพ่ Knight of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้มีการเดินทาง การแสวงหาความก้าวหน้า โครงการใหม่ๆ ความคิดใหม่ๆ การแสวงหาความสมบูรณ์แบบในชีวิต กำลังมีความรัก มีคนบอกรัก หรือเข้าไปยุ่งเกี่ยวกับศิลปะในแขนงใดแขนงหนึ่ง", image: "/images/tators/v1/tr_34.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_33"},
    { id: 35, name: "Queen of Cups", description: "ท่านใดเปิดไพ่ Queen of Cups ไพ่นี้ปรากฏขึ้นมา สำหรับบุคคลที่มีความสามารถเฉพาะด้าน หรือมีความสามารถหลากหลาย อาจจะได้พิสูจน์ตนเองกับผู้คนรอบข้าง ได้รับมอบหมายหน้าที่สำคัญเป็นที่รักของเพื่อนฝูง และ คนรอบข้าง", image: "/images/tators/v1/tr_35.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_34"},
    { id: 36, name: "King of Cups", description: "ท่านใดเปิดไพ่ King  of Cups ไพ่นี้ปรากฏขึ้นมา วันนี้จะมีเรื่องการตัดสินใจ การจัดการที่ต้องใช้เหตุผล ที่ต้องใช้ความเป็นเป็นผู้ใหญ่หนักแน่น และประสบการณ์ในตัวคุณ มีจิตใจโอบอ้อมอารี มีความห่วงใย การช่วยเหลือ", image: "/images/tators/v1/tr_36.jpg" , artist:"อัครวุทธ โรจน์อังคณาวุฒิ",itemId:"art1_35"},
  
    { id: 37, name: "Ace of Pentacles", description: "ท่านใดเปิดไพ่ Ace of Pentacles ไพ่นี้ปรากฏขึ้นมา คือการเริ่มต้นใหม่ ที่มีโอกาสที่ดีในอนาคต การได้รับโอกาสใหม่ๆ  พัฒนาให้มั่นคง การลงทุนลงแรงไปแล้ว  ได้รับผลตอบแทน ในทางที่ดี", image: "/images/tators/v1/tr_37.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_36"},
    { id: 38, name: "Two of Pentacles", description: "ท่านใดเปิดไพ่  Two of Pentacles ไพ่นี้ปรากฏขึ้นมา การจัดการเวลา จัดการความสำคัญให้สิ่งต่างๆให้ราบรื่น การปรับตัว สมดุล การไม่สามารถเลือกทำอย่างใดอย่างหนึ่งได้ชัดเจน", image: "/images/tators/v1/tr_38.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_37"},
    { id: 39, name: "Three of Pentacles", description: "ท่านใดเปิดไพ่  Three of Pentacles ไพ่นี้ปรากฏขึ้นมา การหารือการวางแผน การประชุม การร่วมมือกัน การวางแผนเพื่ออนาคตที่มั่นคง", image: "/images/tators/v1/tr_39.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_38"},
    { id: 40, name: "Four of Pentacles", description: "ท่านใดเปิดไพ่  Four  of Pentacles ไพ่นี้ปรากฏขึ้นมา มีความหมายถึง การหวง ป้องกัน รักษา สิ่งที่มีค่าของตัวเอง การกั๊ก การไม่เปิดเผยใจ เปิดใจ", image: "/images/tators/v1/tr_40.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_39"},
    { id: 41, name: "Five of Pentacles", description: "ท่านใดเปิดไพ่  Five  of Pentacles ไพ่นี้ปรากฏขึ้นมาใบนี้มีความหมายถึง การขาดแคลน ยากไร้ ความจน ความไม่ปลอดภัย ไม่มั่นคง", image: "/images/tators/v1/tr_41.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_40"},
    { id: 42, name: "Six of Pentacles", description: "ท่านใดเปิดไพ่ Six of Pentacles ไพ่นี้ปรากฏขึ้นมาใบนี้หมายถึง การแบ่งปัน ความใจดี การให้โดยไม่ได้รับสิ่งตอบแทน ช่วยเหลือผู้อื่นโดยไม่ได้หวังจะให้มีการตอบแทนกลับมา แต่ถ้าได้รับมา ก็รับโดยไม่ได้มีการตอบแทนกลับไปใดๆ", image: "/images/tators/v1/tr_42.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_41"},
    { id: 43, name: "Seven of Pentacles", description: "ท่านใดเปิดไพ่ Seven of Pentacles ไพ่นี้ปรากฏขึ้นมามีความหมายถึง การประเมินผล สิ่งที่ได้ลงทุนลงแรงไปประเมินสถานการณ์ วางแผนว่าจะทำอย่างไรต่อไป", image: "/images/tators/v1/tr_43.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_42"},
    { id: 44, name: "Eight of Pentacles", description: "ท่านใดเปิดไพ่ Eight of Pentacles ไพ่นี้ปรากฏขึ้นมามีความหมายถึง การทำหรืออดทนทำในสิ่งที่มีคุณค่า ทำซ้ำๆ ที่ได้ผลลัพธ์ ที่รู้แน่ชัดว่า ความใส่ใจ ความตั้งใจในการสร้างสิ่งที่มั่นคง", image: "/images/tators/v1/tr_44.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_43"},
    { id: 45, name: "Nine of Pentacles", description: "ท่านใดเปิดไพ่ Nine of Pentacles ไพ่นี้ปรากฏขึ้นมา มีความหมายถึง ความมีพร้อมสมบูรณ์ ความพอใจในสิ่งที่มีความสุขแต่ยังขาดบางอย่างไป ความมั่นคง ปลอดภัย จากทรัพย์สิน การได้รับรางวัลตัวเองหลังจากการทำงาน", image: "/images/tators/v1/tr_45.jpg", artist:"ประเสริฐศักดิ์ มณีโชติ" ,itemId:"art1_44"},
    { id: 46, name: "Ten of Pentacles", description: "ท่านใดเปิดไพ่ Ten of Pentacles  ไพ่นี้ปรากฏขึ้นมา มีความหมายถึง ความสุข ความสมบูรณ์ ความมั่นคง ความปลอดภัย ครอบครัว ทรัพย์สิน มรดก การลงทุนที่มั่นคงและได้ผลตอบแทนดี", image: "/images/tators/v1/tr_46.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_45"},
    { id: 47, name: "Page of Pentacles", description: "ท่านใดเปิดไพ่  Page Of Pentacles ไพ่นี้ปรากฏขึ้นมา มีความหมายถึง การได้รับโอกาส ได้รับทรัพย์สิน สิ่งของ ซึ่งมีโอกาสพัฒนาไปในทางที่ดี มั่นคงได้ การเฝ้ารอ การเฝ้าดูพิจารณาโอกาสที่ได้ รับเข้ามา", image: "/images/tators/v1/tr_47.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_46"},
    { id: 48, name: "Knight of Pentacles", description: "ท่านใดเปิดไพ่ Knight Of Pentacles ไพ่นี้ปรากฏขึ้นมา มีความหมายถึง ความต่อเนื่อง มั่นคง กิจวัตร ทำซ้ำ ท่านที่สามารถสร้างงาน มั่นคง และสามารถทำในสิ่งที่เป็น กิจวัตร ซ้ำๆ ได้แม้จะดูน่าเบื่อ", image: "/images/tators/v1/tr_48.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_47"},
    { id: 49, name: "Queen of Pentacles", description: "ท่านใดเปิดไพ่ Queen of Pentacles ไพ่นี้ปรากฏขึ้นมาความอบอุ่น การดูแล ความมั่นคง ความอุดสมบูรณ์ ทางการเงิน", image: "/images/tators/v1/tr_49.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_48"},
    { id: 50, name: "King of Pentacles", description: "ท่านใดเปิดไพ่ King of Pentacles ไพ่นี้ปรากฏขึ้นมา หมายถึง ความมั่งคั่ง สมบูรณ์พูนสุข ท่านที่มีความเป็นผู้ใหญ่ มีความคิด  มั่งคั่งและสมบูรณ์ในด้านวัตถุ", image: "/images/tators/v1/tr_50.jpg" , artist:"ประเสริฐศักดิ์ มณีโชติ",itemId:"art1_49"},

    //แก้สะระแล้ว
    { id: 51, name: "Ace of Swords", description: "ท่านใดเปิดไพ่  Ace of Swords ไพ่นี้ปรากฏขึ้นมาการฝ่าฟันอุปสรรคหรือปัญหาที่เข้ามาในชีวิตด้วยความเด็ดเดี่ยวจนได้รับชัยชนะและผ่านอุปสรรคไปได้โดยดี", image: "/images/tators/v1/tr_51.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_50"},
    { id: 52, name: "Two of Swords", description: "ท่านใดเปิดไพ่ Two of Swords ไพ่นี้ปรากฏขึ้นมาคือการรับรู้และการตัดสินใจ การใคร่ครวญ ไม่หุนหันพลันแล่นอาจรวมถึงเรื่องที่คอยต้องจัดการปัญหาต่างๆด้วยความรอบคอบ", image: "/images/tators/v1/tr_52.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_51"},
    { id: 53, name: "Three of Swords", description: "ท่านใดเปิดไพ่ Three  of Swords ไพ่นี้ปรากฏขึ้นมาสื่อถึงการเจ็บปวด ความเศร้า สูญเสีย อีกนัยยะหนึ่งคือการรักษาผ่าตัด หรือการตัดสินใจให้เด็ดเดี่ยว", image: "/images/tators/v1/tr_53.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_52"},
    { id: 54, name: "Four of Swords", description: "ท่านใดเปิดไพ่ Four  of Swords ไพ่นี้ปรากฏขึ้นมาการพักฟื้นหรือหยุดจากสิ่งที่ทำ การพักผ่อน หรือการหยุด ความเครียด ความกังวล", image: "/images/tators/v1/tr_54.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_53"},
    { id: 55, name: "Five of Swords", description: "ท่านใดเปิดไพ่ Five  of Swords ไพ่นี้ปรากฏขึ้นมา ไพ่แห่งการละทิ้งหรือต้องคอยรับภาระจากคนอื่นที่ได้ทิ้งไว้หรือการรับโอกาสงานใหม่ๆ", image: "/images/tators/v1/tr_55.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_54"},
    { id: 56, name: "Six of Swords", description: "ท่านใดเปิดไพ่  Six of Swords ไพ่นี้ปรากฏขึ้นมา การออกจากปัญหาแสวงหาสิ่งใหม่ๆ บางทีปัญหาบางอย่างก็แก้ไขไม่ได้จึงต้องเดินออกมาจากเรื่องที่เกิดขึ้น", image: "/images/tators/v1/tr_56.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_55"},
    { id: 57, name: "Seven of Swords", description: "ท่านใดเปิดไพ่  Seven of Swords ไพ่นี้ปรากฏขึ้นมา การโดนฉวยโอกาสหรือโดนแย่งผลประโยชน์หรืออีกนัยยะคนที่มีปัญหากับเราจะออกจากชีวิตเราไปอาจจะต้องเสียสละบางอย่างเพื่อน ามาซึ่งความไม่ต้องมีปัญหาในอนาคต", image: "/images/tators/v1/tr_57.jpg", artist:"พิชาภพ พยัคโส" ,itemId:"art1_56"},
    { id: 58, name: "Eight of Swords", description: "ท่านใดเปิดไพ่  Eight of Swords ไพ่นี้ปรากฏขึ้นมาการถูกผูกมัดจากสถานการณ์หรือเหตุการณ์ที่ตัดสินใจได้ยากเรื่องที่เกี่ยวข้องกับการจำกัดอิสรภาพจึงต้องใช้สติให้มากในการแก้ปัญหาที่เกิดขึ้น", image: "/images/tators/v1/tr_58.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_57"},
    { id: 59, name: "Nine of Swords", description: "ท่านใดเปิดไพ่  Nine of Swords ไพ่นี้ปรากฏขึ้นมา ออกจากเรื่องเลวร้ายจึงต้องให้เวลาในการจะทำอะไรต่อไปในอนาคต", image: "/images/tators/v1/tr_59.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_58"},
    { id: 60, name: "Ten of Swords", description: "ท่านใดเปิดไพ่  Ten  of Swords ไพ่นี้ปรากฏขึ้นมา จุดสิ้นสุดได้เดินมาถึงไม่ว่าจะความกังวลเรื่องร้ายต่างๆ เป็นการจะได้เริ่มต้นอะไรใหม่ๆในเร็วๆนี้", image: "/images/tators/v1/tr_60.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_59"},
    { id: 61, name: "Page of Swords", description: "ท่านใดเปิดไพ่  Page  of Swords ไพ่นี้ปรากฏขึ้นมาไพ่แห่งความเชื่อมั่นในตนเองและความอดทนเพื่อเอาชนะปัญหาและอุปสรรคต่างๆ", image: "/images/tators/v1/tr_61.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_60"},
    { id: 62, name: "Knight of Swords", description: "ท่านใดเปิดไพ่ Knight of Swords ไพ่นี้ปรากฏขึ้นมาความกล้าหาญทุ่มเท การใช้แรงกายและแรงใจในการต่อสู้ให้ได้มาเพื่อความสำเร็จ", image: "/images/tators/v1/tr_62.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_61"},
    { id: 63, name: "Queen of Swords", description: "ท่านใดเปิดไพ่ Queen of Swords ไพ่นี้ปรากฏขึ้นมาความเด็ดเดี่ยวมั่นใจ อิสระเสรีภาพ ความเที่ยงธรรมตรงไปตรงมา", image: "/images/tators/v1/tr_63.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_62"},
    { id: 64, name: "King of Swords", description: "ท่านใดเปิดไพ่ King of Swords ไพ่นี้ปรากฏขึ้นมา ความสุขุมรอบคอบ ใช้วิจารณญาณในการคิดวิเคราะห์เพื่อขจัดปัญหาและอุปสรรค", image: "/images/tators/v1/tr_64.jpg" , artist:"พิชาภพ พยัคโส",itemId:"art1_63"},
  
    { id: 65, name: "Ace of Wands", description: "ท่านใดเปิดไพ่ Ace  of wands ไพ่นี้ปรากฏขึ้นมา การต้านทานแห่งขุมพลังภายในจิตใจที่พร้อมเริ่มต้นใหม่ ด้วยพละ  5 คือ กำลังห้าประการได้แก่ ศรัทธาพละ วิริยะพละ กำลังการควบคุมความเกียจคร้าน ความระลึกได้กำลังการควบคุมความประมาท แล้วสิ่งดีจะบังเกิด", image: "/images/tators/v1/tr_65.jpg", artist:"มงคล  มะอาจเลิศ" ,itemId:"art1_64"},
    { id: 66, name: "Two of Wands", description: "ท่านใดเปิดไพ่ Two  of wands ไพ่นี้ปรากฏขึ้นมา เทพแห่งความสำเร็จ มองการณ์ไกล ทุกสิ่งอย่างที่เกิดขึ้นภายใต้มุมมองของดวงตาแห่งความสร้างสรรค์และแรงปารถนาที่สามรถใช้สติปัญญาตัดสินใจทำการสิ่งใดได้เด็จขาดและแม่นยำ", image: "/images/tators/v1/tr_66.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_65"},
    { id: 67, name: "Three of Wands", description: "ท่านใดเปิดไพ่ Three  of wands ไพ่นี้ปรากฏขึ้นมาแสงแห่งความรักและความหวัง ให้เดินไปตามเสียงเรียกร้องของหัวใจทุกสิ่งอย่างจงมองด้วยสติปัญญาและวางแผนไปอย่างรอบคอม และจะบังเกิดผลไปในที่ดี", image: "/images/tators/v1/tr_67.jpg", artist:"มงคล  มะอาจเลิศ" ,itemId:"art1_66"},
    { id: 68, name: "Four of Wands", description: "ท่านใดเปิดไพ่ Four of wands ไพ่นี้ปรากฏขึ้นมานั่นคือสัญญาณของความมั่นคง ยั่งยืนนาน  ท่านใดกำลังมองหา คู่ใจจังหวะดีๆจะเกิดมาพร้อม ความเข้าอกเข้าใจ ที่จะมาคอย ดูแลชีวิตทั้งสองเป็นอย่างดี", image: "/images/tators/v1/tr_68.jpg", artist:"มงคล  มะอาจเลิศ" ,itemId:"art1_67"},
    { id: 69, name: "Five of Wands", description: "ท่านใดเปิดไพ่ Five of wand ไพ่นี้ปรากฏขึ้นมานั่นคือ สัญญาณที่บ่งบอกให้ ความเหน็ดเหนื่อยการแข่งขันการแย่งชิง", image: "/images/tators/v1/tr_69.jpg", artist:"มงคล  มะอาจเลิศ" ,itemId:"art1_68"},
    { id: 70, name: "Six of Wands", description: "ท่านใดเปิดไพ่ Six of wand ไพ่นี้ปรากฏขึ้นมาโครงการต่างๆที่วางแผนงานไว้ ได้มีการเริ่ม จะประสบผลสำเร็จได้ด้วยดี", image: "/images/tators/v1/tr_70.jpg", artist:"มงคล  มะอาจเลิศ" ,itemId:"art1_69"},
    { id: 71, name: "Seven of Wands", description: "ท่านใดเปิดไพ่ Seven of wand ไพ่นี้ปรากฏขึ้นมาในแง่ความการงาน นั่นคือ สัญญาณเตือนว่า... ดวงกำลังดี งานที่มีปัญหา ขัดแย้ง อิโก้ลดลงบ้าง ปรับความเข้าใจ แล้ว บรรยากาศดีๆจะคืนกลับมา", image: "/images/tators/v1/tr_71.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_70"},
    { id: 72, name: "Eight of Wands", description: "ท่านใดเปิดไพ่ Seven of wand  ไพ่นี้ปรากฏขึ้นมาความหมายเป็นการก้าวหน้าของ หน้าที่การงาน การเลื่อนขั้น การเงินการทองจะไปในทิศที่ดี ทำการสิ่งใด สำเร็จลุล่วงด้วยดี", image: "/images/tators/v1/tr_72.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_71"},
    { id: 73, name: "Nine of Wands", description: "ท่านใดเปิดไพ่ Nine  of wand  ไพ่นี้ปรากฏขึ้นมา จะมีนิมิตร สัญญาณเตือนให้เรา หันมาดูแลจิตใจตัวเอง คนมีคู่รัก ถึงเวลาที่จะนำ สิ่งที่ผิดพลาดในอดีตมาทบทวน และปรับปรุง ที่จะทำให้ ความรัก ความสัมพันธ์ จะมีการเปลี่ยนแปลงที่ดี", image: "/images/tators/v1/tr_73.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_72"},
    { id: 74, name: "Ten of Wands", description: "ท่านใดเปิดไพ่ Ten of Wands ไพ่นี้ปรากฏขึ้นมา บ่งบอกการกอดรัดและรักษาความเป็นเอกภาพ ให้ทุกสิ่งอย่างเป็นไปในทางที่แก้ไขสถานการณ์ได้ เป้าหมายใดๆที่อยู่ภายใต้จิตสำนึก จะไม่ลดละต่อเจตนาเพื่อสำเร็จในอนาคต", image: "/images/tators/v1/tr_74.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_73"},
    { id: 75, name: "Page of Wands", description: "ท่านใดเปิดไพ่ Page of Wands ไพ่นี้ปรากฏขึ้นมา จะเห็นเทพกวนอู แสดงถึงความเคารพต่อความซื่อสัตย์ต่อตนเองและผู้อื่น สิ่งเหล่านี้เป็นการตกผลึกจากช่วงวัยหนุ่มผ่านประสบการณ์เผชิญกับปัญหา ทำสิ่งใดควรใช้ตรรกะองค์ความรู้มากๆ เพื่อไม่ให้เกิดการเสียหายยากที่แก้ไข", image: "/images/tators/v1/tr_75.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_74"},
    { id: 76, name: "Knight of Wands", description: "ท่านใดเปิดไพ่ Knight of Wands ไพ่นี้ปรากฏขึ้นมา จะมีการเดินทางอย่างเด็ดเดี่ยว พร้อมผจญภัย กับสิ่งต่างๆเปิดรับสิ่งใหม่ๆ ทุ่มสุดตัวทำทุกสิ่งอย่าง เพื่อความสำเร็จ", image: "/images/tators/v1/tr_76.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_75"},
    { id: 77, name: "Queen of Wands", description: "ท่านใดเปิดไพ่ Queen of Wands ไพ่นี้ปรากฏขึ้นมาไพ่แห่งมหาเสน่ห์ มีสังคมที่ดี เพื่อนฝูงยอมรับโดยจริตนิสัยเป็นไปอน่างธรรมชาติมีความมุ่งมั่นใจสูง สามารถยืนหยัดด้วยความสามรถของตนเอง", image: "/images/tators/v1/tr_77.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_76"},
    { id: 78, name: "King of Wands", description: "ท่านใดเปิดไพ่ King of Wands ไพ่นี้ปรากฏขึ้นมามีจิตนาการสูง สร้างมโนภาพแห่งความฝัน แล้วลงมือทำอย่างมีพลัง เจ้าโปรเจค บุคลิกมีความเป็นผู้นำสูง", image: "/images/tators/v1/tr_78.jpg" , artist:"มงคล  มะอาจเลิศ",itemId:"art1_77"},

   


];

  