import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";

interface ReferralCode {
  "refferal-code": string;
  distributorType: string;
  user: {
    name: string;
    lastname: string;
  };
  bank: {
    bankAccount: string;
    bankName: string;
  };
}

interface Item {
  itemId: string;
  ownerId: string;
}

interface Transaction {
  itemId: string;
  amount: number;
  refferalCode: string;
  status: string;
  updatedAt: string;
}

const BackEndPayUsers: React.FC = () => {
  const [referralCodes, setReferralCodes] = useState<ReferralCode[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    // fetchAllData();
  }, []);

  const fetchUsers = async () => {
    try {
      const referralResponse = await axios.get(
        "https://artelu-api.vercel.app/api/admin/backEndManager"
      );
      const referralData: ReferralCode[] = referralResponse.data;
      setReferralCodes(referralData);

      const type1Referrals = referralData.filter(
        (referral) => referral.distributorType === "1"
      );
      const type2Or3Referrals = referralData.filter((referral) =>
        ["2", "3"].includes(referral.distributorType)
      );

      if (type1Referrals.length > 0) {
        await fetchNormal(type1Referrals);
      }
      if (type2Or3Referrals.length > 0) {
        await fetchArtFortune(type2Or3Referrals);
      }
    } catch (error) {
      console.error("Error fetching referral codes:", error);
    }
  };

  const fetchNormal = async (type1Referrals: ReferralCode[]) => {
    try {
      // Fetch transaction data
      const transactionsResponse = await axios.get(
        "https://artelu-api.vercel.app/api/admin/backEndManager/getItemsPaid"
      );
      const transactionsData: Transaction[] = transactionsResponse.data;

      // Filter transactions by date
      const filteredTransactions = filterByDate(
        transactionsData.filter((transaction) => transaction.status === "PAID") // Include only "PAID" transactions
      );

      // Process referral data and calculate total amounts
      const combinedData = type1Referrals.map((referral) => {
        const totalAmount = filteredTransactions.reduce((acc, transaction) => {
          if (transaction.refferalCode === referral["refferal-code"]) {
            return acc + transaction.amount * 0.1; // Apply 10% for type1Referrals
          }
          return acc;
        }, 0);

        return {
          referralCode: referral["refferal-code"],
          name: `${referral.user.name} ${referral.user.lastname}`,
          bank: referral.bank,
          distributorType: referral.distributorType,
          amount: totalAmount,
        };
      });

      // Update filtered data
      setFilteredData((prev) => [
        ...prev,
        ...combinedData.filter((entry) => entry.amount > 0), // Only include entries with an amount > 0
      ]);
    } catch (error) {
      console.error("Error fetching normal transactions:", error);
    }
  };

  const fetchArtFortune = async (type2Or3Referrals: ReferralCode[]) => {
    try {
      // Fetch items data
      const itemsResponse = await axios.get(
        "https://artelu-api.vercel.app/api/admin/backEndManager/getItemSetting"
      );
      const itemsData: Item[] = itemsResponse.data;

      // Fetch transactions data
      const transactionsResponse = await axios.get(
        "https://artelu-api.vercel.app/api/admin/backEndManager/getItemsPaid"
      );
      const transactionsData: Transaction[] = transactionsResponse.data;

      // Filter transactions by "PAID" status and date
      const filteredTransactions = filterByDate(
        transactionsData.filter((transaction) => transaction.status === "PAID")
      );

      // Process each referral to calculate their total amount
      const combinedData = type2Or3Referrals.map((referral) => {
        // Find all items owned by the current referral
        const matchedItems = itemsData.filter(
          (item) => item.ownerId === referral["refferal-code"]
        );

        // Calculate the total amount based on matched transactions
        const totalAmount = matchedItems.reduce((acc, item) => {
          const transaction = filteredTransactions.find(
            (t) => t.itemId === item.itemId
          );
          // Add 25% of the transaction amount to the total
          return acc + (transaction?.amount || 0) * 0.25;
        }, 0);

        // Return structured data for the current referral
        return {
          referralCode: referral["refferal-code"],
          name: `${referral.user.name} ${referral.user.lastname}`,
          bank: referral.bank,
          distributorType: referral.distributorType,
          amount: totalAmount,
        };
      });

      // Filter out entries with a zero amount and update the state
      setFilteredData((prev) => [
        ...prev,
        ...combinedData.filter((entry) => entry.amount > 0),
      ]);
    } catch (error) {
      console.error("Error fetching Art/Fortune transactions:", error);
    }
  };

  const filterByDate = (transactions: Transaction[]): Transaction[] => {
    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.updatedAt); // Convert transaction date
      const start = startDate ? new Date(startDate) : null; // Start date filter
      const end = endDate ? new Date(endDate) : null; // End date filter

      if (start && end) {
        // Check if transaction date is within the range (inclusive)
        return (
          transactionDate >= start &&
          transactionDate <= new Date(end.getTime() + 24 * 60 * 60 * 1000 - 1) // Include the end date
        );
      } else if (start) {
        // If only start date is provided
        return transactionDate >= start;
      } else if (end) {
        // If only end date is provided
        return transactionDate <= end;
      }
      // If no startDate or endDate is provided, include all transactions
      return true;
    });
  };

  const fetchAllData = async () => {
    setIsLoading(true);
    await fetchUsers();
    setIsLoading(false);
  };

  const validateInputs = () => {
    if (!fileName.trim()) {
      Swal.fire({
        icon: "error",
        title: "ชื่อไฟล์ว่าง",
        text: "กรุณากรอกชื่อไฟล์ก่อนดำเนินการ",
      });
      return false;
    }
  
    if (!startDate) {
      Swal.fire({
        icon: "error",
        title: "วันที่เริ่มว่าง",
        text: "กรุณากรอกวันที่เริ่ม",
      });
      return false;
    }
  
    if (!endDate) {
      Swal.fire({
        icon: "error",
        title: "วันที่จบว่าง",
        text: "กรุณากรอกวันที่จบ",
      });
      return false;
    }
  
    return true;
  };
  
  const handleGenerate = () => {
    if (validateInputs()) {
      fetchAllData(); // เรียก fetchAllData ถ้าผ่านการตรวจสอบ
    }
  };

  const exportToExcel = () => {
    // Map data for export
    const exportData = filteredData.map((entry) => ({
      ผู้ใช้: entry.referralCode,
      ชื่อ: entry.name,
      ประเภท:
        entry.distributorType === "1"
          ? "ตัวแทนจำหน่าย"
          : entry.distributorType === "2"
          ? "ศิลปิน"
          : entry.distributorType === "3"
          ? "หมอดู"
          : "ไม่ระบุ",
      เลขบัญชี: entry.bank?.bankAccount || "N/A",
      ชื่อธนาคาร: entry.bank?.bankName || "N/A",
      "ยอด(thb)": entry.amount.toFixed(2),
    }));

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Data");

    // Write workbook to buffer and save
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${fileName || "FilteredData"}.xlsx`);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">สร้างรอบโอนสมาชิก</h2>

      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {/* แสดง Filters และปุ่ม Apply Filter เมื่อยังไม่มีข้อมูล */}
          {filteredData.length === 0 && (
            <div className="row mb-5 bg-light p-4">
              <h5 className="mb-2 text-center">กรอกชื่อไฟล์รอบโอนของวัน เริ่ม - จบ ให้ถูกต้อง</h5>
              <div className="col-md-12">
                <label htmlFor="fileName" className="form-label">
                  ชื่อไฟล์
                </label>
                <input
                  type="text"
                  id="fileName"
                  className="form-control"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  placeholder="กรุณาใส่ชื่อไฟล์"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="startDate" className="form-label">
                  เริ่มวันที่
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-5">
                <label htmlFor="endDate" className="form-label">
                  จบวันที่
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="col-md-12 d-flex align-items-end">
                <button
                  className="btn btn-primary w-100"
                  onClick={handleGenerate}
                >
                  สร้าง
                </button>
              </div>
            </div>
          )}

          {/* แสดงปุ่ม Export และข้อมูลเมื่อข้อมูลถูกสร้าง */}
          {filteredData.length > 0 && (
            <div className="bg-light p-4">
              <h4 className="col-md-12 mb-5 text-center">ข้อมูลรอบโอน</h4>
              <div className="mb-3 bg-dark p-2">
                <button
                  className="btn btn-success"
                  onClick={exportToExcel}
                  disabled={filteredData.length === 0}
                >
                  Export to Excel
                </button>
                <button
                  className="btn text-bg-light btn-warning m-1"
                  onClick={() => window.location.reload()}
                >
                  สร้างใหม่
                </button>
              </div>

              <table className="table table-bordered table-hover bs-body-color bg-light p-1">
                <thead className="table-dark">
                  <tr>
                    <th>บัญชี</th>
                    <th>ชื่อ</th>
                    <th>ส่วนแบ่ง</th>
                    <th>เลขบัญชี</th>
                    <th>ยอด</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.referralCode}</td>
                      <td>{entry.name}</td>
                      <td>
                        {entry.distributorType === "1"
                          ? "ตัวแทนจำหน่าย"
                          : entry.distributorType === "2"
                          ? "ศิลปิน"
                          : entry.distributorType === "3"
                          ? "หมอดู"
                          : "ไม่ระบุ"}
                      </td>
                      <td>
                        {entry.bank?.bankAccount || "N/A"} /{" "}
                        {entry.bank?.bankName || "N/A"}
                      </td>
                      <td>{entry.amount.toFixed(2)} บาท</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* ขึ้นปุ่มกลับทุกหน้า */}
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/backEndManager/itemManager")}
            >
              กลับไป
            </button>
          </div>
          <div className="col-md-12 mt-5 mb-5 text-secondary">
                <p className="border-top my-3"></p> {/* เส้นขั้นบรรทัด */}
                <p className="font-weight-bold">อ่านการใช้งาน</p>
                <p>
                  <strong>ข้อมูลแสดง:</strong>
                  <br />
                  แสดงข้อมูลสมาชิกทั้งหมด
                </p>
                <p>
                  <strong>การใช้งาน:</strong>
                  <br />
                  1.ตรวจสอบอนุมัติสมาชิก ตัวแทนจำหน่าย ศิลปิน หมอดู..<br />
                  2.ปรับแกไขข้อมูลสมาชิก<br />
                  3.สร้างสินค้า<br />
                  4.แสดงสินค้าที่ขายได้<br />
                  5.สร้างรอบโอนเงินให้สมาชิก<br />
                </p>
              </div>
        </div>
      )}
    </div>
  );
};

export default BackEndPayUsers;
